import { history } from '../../routers/AppRouter'
import axios  from 'axios'
import setAuthToken from '../../utils/setAuthToken'
import { AUTH_ERROR,
  LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, 
  CURRENT_USER_LOADED, UPDATE_CURRENT_USER } from "./types"
// import { store } from '../configStore'

let client_id =""
let client_id_vk =''
const routerBasePath ='/.netlify/functions'
let uri =""
let uriVK =""

console.log('process.env.NODE_ENV =',process.env.NODE_ENV) // --> gets production

  // client_id =process.env.REACT_APP_CLIENT_ID_8888
  client_id =process.env.REACT_APP_CLIENT_ID
  // client_id_vk =process.env.REACT_APP_CLIENT_ID_VK_8888
  client_id_vk =process.env.REACT_APP_CLIENT_ID_VK
  uri ="https://project-result.site/"
  uriVK ="https://project-result.site/vk"
  // uri ="http://localhost:8888"   /// netlify dev runs build functions -> production
  // uriVK ="http://localhost:8888/vk"   /// netlify dev runs build functions -> production

export const startLogin_VK =() =>{
    return () =>{      
      window.location.assign(`https://oauth.vk.com/authorize?client_id=${client_id_vk}&display=page&redirect_uri=${uriVK}&scope=email&response_type=code&v=5.131`) 
    };
};

export const startLogin_Ya = async() =>{
    window.location.assign(`https://oauth.yandex.ru/authorize?response_type=code&client_id=${client_id}&redirect_uri=${uri}`)
}

// Authenticate user & authorize him (get app token):
export const redirectLogin_Ya =(code) => async dispatch=>{
  try{
      const res =await axios.post(`${routerBasePath}/auth-y`, {code:code})
      // dispatch(login({email: res.data.email}))
      
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });

    } catch(err){
      console.log(err)
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.msg
      });
    }
}

// Authenticate user & authorize him (get app token):
export const redirectLogin_VK =(code) => async dispatch=>{ 
  try{
      const res =await axios.post(`${routerBasePath}/auth-vk`, {code:code}) //-->token
      // console.log('res =', res.data)///
     
      // dispatch(login({email: res.data.email})) //not needed we dispatch here:

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      
    } catch(err){
      console.log(err)
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.msg
      });
    }
}

// Load User
export const loadUser = () =>async dispatch=>{
  // console.log('loadUser started')//
  try {
    const res = await axios.get(`${routerBasePath}/auth`);
// console.log('res.data =', res.data)
    dispatch({
      type: CURRENT_USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

// Login user -- used after authentication only or Demo users
export const login = (emailObj) =>async dispatch=>{
  // console.log('User login started, user=', emailObj)/////
  try {
    const res = await axios.post(`${routerBasePath}/auth`, emailObj);
    
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    // return res.data.token
  } catch (err) {
    console.log(err)//
    dispatch({
      type: LOGIN_FAIL,
      payload: err.response.data.msg
    });
  }
};

export const updateCurrentUser =(updatedUser) =>dispatch=>{
  dispatch({
    type: UPDATE_CURRENT_USER,
    payload: updatedUser
  })
}

export const logout =()=>dispatch=>{
  setAuthToken()  
  dispatch({
    type: LOGOUT
  })
  history.push('/')
}