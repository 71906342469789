import moment from "moment"
const date =`${moment().format('DD-MM-YYYY, HH:mm')}`

export const editProject =(args)=>{
    const {
        projectName,
        description,
        userID,
        currentProject,
        setCurrentProject,
        updateProject,
        updateUser,
        users
      } =args

    // Add User to Project ---
    let userExists =null
    let newProjectUserData =null
    let newProjectUser =null

    //Check that no such user in Current Project already
    userExists =currentProject.users.find(user=>user.id===userID)

    if(userID && !userExists){
      // Find & Get New User data
      newProjectUser =users.find(user => user._id===userID)
      newProjectUserData ={
        name: newProjectUser.firstName+' '+newProjectUser.lastName,
        email: newProjectUser.email,
        role: newProjectUser.role,
        id: newProjectUser._id
      }
    }
    const projectUsers =newProjectUserData ? [...currentProject.users, newProjectUserData] : currentProject.users
     
    const updatedProject = {
      ...currentProject,
      name: projectName,
      description,
      users: projectUsers,
      date
    };

    updateProject(updatedProject);

    // Add Project to User in Users ---
      // Check that no such Project for this User already
    if(userID && newProjectUser){
      updateUser({
        ...newProjectUser,
        projects: [...newProjectUser.projects, {name:currentProject.name, id:currentProject.id}]
      })
    }  
    setCurrentProject(updatedProject)  
}