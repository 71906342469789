import React from 'react'
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PublicRoute =({
    isAuthenticated,
    children
})=> (
    isAuthenticated ? <Navigate to="/dashboard" replace={true} /> : children
)

const mapStateToProps =(state) =>({
    isAuthenticated: !!state.auth.isAuthenticated
  });
  
  export default connect(mapStateToProps)(PublicRoute);