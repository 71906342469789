import { CLEAR_ITEMS, GET_ITEMS, SET_ITEMS, UPDATE_ITEMS, UPDATE_ITEMS_TOP } from "../actions/types";

const initialState ={
    filteredItems: null,
    filteredItemsTop: null,
    tableItems: null,
    search: '',
    searchTop: '',
    tableTerm: ''
}

const searchReducer =(state=initialState, action)=>{
    switch(action.type){
        case SET_ITEMS:
            return action.payload;
        case UPDATE_ITEMS:
            return {
                ...state,
                filteredItems: action.payload.items,
                search: action.payload.search
            };
        case UPDATE_ITEMS_TOP:
            return {
                ...state,
                filteredItems: action.payload.items,
                searchTop: action.payload.searchTop
            };
        case GET_ITEMS:
            return state;
        case CLEAR_ITEMS:
            return [];
        default: return state
    }
}

export default searchReducer