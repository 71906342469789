import React , { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { deleteProject, setCurrentProject} from '../../../store/actions/projectActions'
import { updateUser } from '../../../store/actions/userActions'
import { removeProjectFromUsers } from '../../../functions/removeProjectFromUser'
import Highlighted from './Highlighted'
import M from 'materialize-css/dist/js/materialize.min.js';   

const projectsTH =['Project Name','Description','']

const TableProjects = (props) => {
    const{ setCurrentProject, currentUserRole, deleteProject, updateUser,
        // project: { projects, loading}, users} =props
        users, currentitems, search} =props
        const inactive =currentUserRole==='Submitter' || currentUserRole==='Developer' ? 'notactive' : ''

    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    });
    
    const onDelete =(prjct) =>{
        const args ={
            project: prjct,
            users,
            updateUser
        }
        removeProjectFromUsers(args)
        
        deleteProject(prjct._id);
        M.toast({ html: 'Project Deleted', classes: 'toastStyle'});
    }

    return (
    <table className='grey-text font-size-med'>
        <thead>
        <tr>
            {
            projectsTH !== null &&
            projectsTH.map((el,index) =>(
            <th key={index}>{el}</th>
            ))}
        </tr>
        </thead>
        <tbody>
            {
            currentitems  &&
            currentitems.map((project) =>(
                <tr key={project._id}> 
                    <td><Highlighted text={project.name} highlight={search}/></td>
                    <td>{project.description}</td>
                    <td >
                        <Link to={`/projectDetails/${project._id}`} 
                            className='link link-underlined'
                            onClick={() =>setCurrentProject(project)}
                            >Details
                        </Link>
                        <a href='#!' onClick={() =>onDelete(project)} 
                        className={`secondary-content ${inactive}`}>
                        <i className='material-icons grey-text'>delete</i>
                        </a>
                    </td>
                </tr>       
            ))}
        </tbody>
    </table>
  )
}

TableProjects.propTypes ={
    // getProjects: PropTypes.func.isRequired,
    setCurrentProject: PropTypes.func.isRequired,
    deleteProject: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    users: state.users.users,
    search: state.searchItems.search,
    currentUserRole :state.auth.currentUser.role
  });

const propsActionsObj ={
    // getProjects,
    deleteProject,
    setCurrentProject,
    updateUser
}

// export default TableProjects
export default connect(mapStateToProps, propsActionsObj)(TableProjects)