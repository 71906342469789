import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import NotFoundPage from "../pages/NotFoundPage";
import LoginPage from '../pages/LoginPage'
import DashboardPage from '../pages/DashboardPage'
import LoginDemoPage from "../pages/LoginDemoPage";
import ProjectsPage from '../pages/ProjectsPage'
import ProjectDetailsPage from '../pages/ProjectDetailsPage'
import TicketDetailsPage from '../pages/TicketDetailsPage'
import TicketsPage from "../pages/TicketsPage";
import UserRolesPage from "../pages/UserRolesPage";
import UserProfilePage from "../pages/UserProfilePage";
import RedirectVK from "../pages/RedirectVK";

export const history =createBrowserHistory(); 

export default function AppRouter() {
  return (
    <Router history={history}>
      <Routes>
        <Route path="/" element={<PublicRoute><LoginPage /></PublicRoute>} />
        <Route path="/logindemo" element={<PublicRoute><LoginDemoPage /></PublicRoute>} />
        <Route path="/vk" element={<PublicRoute><RedirectVK /></PublicRoute>} />
        <Route path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
        <Route path="/roles" element={<PrivateRoute><UserRolesPage /></PrivateRoute>} />
        <Route path="/projects" element={<PrivateRoute><ProjectsPage /></PrivateRoute>} />
        <Route path="/projectdetails/:id" element={<PrivateRoute><ProjectDetailsPage /></PrivateRoute>} />
        <Route path="/tickets" element={<PrivateRoute><TicketsPage /></PrivateRoute>} />
        <Route path="/ticketdetails/:id" element={<PrivateRoute><TicketDetailsPage /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><UserProfilePage /></PrivateRoute>} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}
