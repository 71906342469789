import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment'
import PropTypes from 'prop-types';
import { addUser } from '../../store/actions/userActions';
import M from 'materialize-css/dist/js/materialize.min.js';

const AddUserModal = ({ addUser, usersError }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] =useState('')

  useEffect(()=>{
    moment.locale('ru');
  },[])

  useEffect(()=>{
    usersError && 
      M.toast({ html: `${usersError.msg}`, classes:'danger', displayLength: 4000 })
  },[usersError])

  const onSubmit = () => {
    if (firstName === '' || lastName === '' || email ==='') {
      M.toast({ html: 'Please enter the first, last name and email', classes:'danger' });
    } else {
      const joinDate =`${moment().format('DD-MM-YYYY, HH:mm')}`
      addUser({
        firstName,
        lastName,
        email,
        role: '',
        avatar: '',
        projects: [],
        tickets: [],
        // joinDate: new Date()
        joinDate
      }).then((user) =>{
        console.log('user added =', user)
        if(user){
          M.toast({ html: `${firstName} ${lastName} was added as a User` });
        }
      })

      // Clear Fields
      setFirstName('');
      setLastName('');
      setEmail('')
    }
  };

  return (
    <div id='add-user-modal' className='modal'>
      <div className='modal-content'>
        <h4>New User</h4>
        <div className='row'>
          <div className='input-field'>
            <input
              type='text'
              name='firstName'
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
            <label htmlFor='firstName' className='active'>
              First Name
            </label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field'>
            <input
              type='text'
              name='lastName'
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
            <label htmlFor='lastName' className='active'>
              Last Name
            </label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field'>
            <input
              type="email" className="validate"
              name='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <label htmlFor='email' className='active'>
              Email
            </label>
          </div>
        </div>

      </div>
      <div className='modal-footer'>
        <a
          href='#!'
          onClick={onSubmit}
          className='modal-close waves-effect blue waves-light btn'
        >
          Enter
        </a>
      </div>
    </div>
  );
};


AddUserModal.propTypes = {
  addUser: PropTypes.func.isRequired,
  usersError: PropTypes.object.isRequired
};

const mapStateToProps=state=>({
  usersError: state.users.error
})

export default connect( mapStateToProps, { addUser })(AddUserModal);