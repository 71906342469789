import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { login, loadUser } from '../store/actions/auth'
import setAuthToken from '../utils/setAuthToken';

const LoginDemoPage =({auth, login, loadUser}) =>{
    const{ token }=auth
    const navigate =useNavigate()

    useEffect(()=>{
        if(token){
          // console.log('Login page access to token changed, token=', auth.token)
        setAuthToken(token) //set headers
        loadUser().then(()=>{
          navigate('/dashboard')
        })
        }
      },[token,loadUser,navigate])

    return (
        <div > 
          <div className="box-layout">           
             <div className="box-layout__box demo-container">
               <h4>
                   <i className='material-icons box-layout__i'>bug_report</i>          
                 Demo_User Login
                </h4>
              <div>
                <div className='row' style={{'marginBottom': 0}}>
                    <div className='col s6 demo-item' 
                    onClick={()=>login({email: "admin@tr.su"})}>
                        <i className='material-icons large box-layout__underline'>person</i>
                        <p className='box-layout__p'>Admin</p>                      
                    </div>
                    <div className='col s6 demo-item'
                    onClick={()=>login({email: "pman@tr.su"})}>
                        <i className='material-icons large red-text text-darken-2 box-layout__underline'>person</i>
                        <p className='box-layout__p'>Project Manager</p>
                    </div>
                </div>
                <div className='row' style={{marginBottom: 0}}>
                    <div className='col s6 demo-item'
                    onClick={()=>login({email: "ddev@tr.su"})}>
                        <i className='material-icons large green-text text-darken-4 box-layout__underline'>person</i>
                        <p className='box-layout__p'>Developer</p>
                    </div>
                    <div className='col s6 demo-item'
                    onClick={()=>login({email: "dsub@tr.su"})}>
                        <i className='material-icons large blue-text text-darken-4 box-layout__underline'>person</i>
                        <p className='box-layout__p'>Submitter</p>
                    </div>
                </div>
                <p>Have an Account? 
                    <Link to="/"> Sign in</Link>
                </p>
              </div>
             </div>
          </div>    
        </div>
      )
}
const mapStateToProps =state =>({
    auth: state.auth
})

const mapDispatchToProps =(dispatch) =>({
    login: (user) => dispatch(login(user)),
    loadUser: ()=>dispatch(loadUser())
  });

export default connect(mapStateToProps, mapDispatchToProps)(LoginDemoPage);
