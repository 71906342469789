import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ProjectSelectOptions = ({ project: { projects, loading } }) => {

  return (
    !loading &&
    projects !== null &&
    projects.map(el => (
      <option key={el._id} value={`${el._id}`}>
        {el.name}
      </option>
    ))
  );
};

ProjectSelectOptions.propTypes = {
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  project: state.projects
});

export default connect( mapStateToProps)(ProjectSelectOptions);