import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import setAuthToken from '../utils/setAuthToken';
import { loadUser, redirectLogin_VK } from '../store/actions/auth'

export function RedirectVK({auth:{error,token}, loadUser, redirectLogin_VK}) {
  let [searchParams] = useSearchParams();
  const[code, setCode] =useState(null)
  const navigate =useNavigate()
  
  // get code
  useEffect(()=>{
    setCode(searchParams.get('code'))
  },[searchParams])

   // Error on Login:
   useEffect(() =>{
    error && navigate('/')
  },[error])
  
  // redirect on code params received:
//   console.log('search Params =', code) ///////////
  useEffect(()=>{
    if(code){
        // console.log('redirectLogin_VK(code) fired')////
      redirectLogin_VK(code)
    }
  },[code,redirectLogin_VK])

  useEffect(()=>{
    if(token){
    //   console.log('Login page access to token changed, token=', token) /////////
    setAuthToken(token) //set headers
    loadUser().then(()=>{
      navigate('/dashboard')
    })
    }
  },[token,loadUser,navigate])
  
  return (
    <div style={{"backgroundColor":"#000"}}></div>
  )
}
const mapStateToProps =state =>({
    auth: state.auth
  })

export default connect(mapStateToProps,{loadUser, redirectLogin_VK})(RedirectVK)