export const GET_TICKETS = 'GET_TICKETS';
export const ADD_TICKET = 'ADD_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';
export const SET_CURRENT_TICKET = 'SET_CURRENT_TICKET';
export const CLEAR_CURRENT_TICKET = 'CLEAR_CURRENT_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const CLEAR_TICKETS = 'CLEAR_TICKETS';
export const TICKETS_ERROR = 'TICKETS_ERROR';
export const SEARCH_TICKETS = 'SEARCH_TICKETS';
export const SET_LOADING = 'SET_LOADING';

export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USERS = 'CLEAR_USERS';
export const USERS_ERROR = 'USERS_ERROR';
export const SEARCH_USERS = 'SEARCH_USERS';

export const GET_PROJECTS = 'GET_PROJECTS';
export const ADD_PROJECT ='ADD_PROJECT'
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const CLEAR_CURRENT_PROJECT = 'CLEAR_CURRENT_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const PROJECTS_ERROR = 'PROJECTS_ERROR';
export const SEARCH_PROJECTS = 'SEARCH_PROJECTS';

export const AUTH_ERROR ='AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT ='LOGOUT'
export const UPDATE_CURRENT_USER ='UPDATE_CURRENT_USER'
export const CURRENT_USER_LOADED ='CURRENT_USER_LOADED'

export const GET_WARNINGS ='GET_WARNINGS'
export const ADD_WARNING ='ADD_WARNIN'
export const DELETE_WARNING = 'DELETE_WARNING';
export const CLEAR_CURRENT_WARNING = 'CLEAR_CURRENT_WARNING'

export const SET_ITEMS ='SET_ITEMS'
export const UPDATE_ITEMS ='UPDATE_ITEMS'
export const UPDATE_ITEMS_TOP ='UPDATE_ITEMS_TOP'
export const GET_ITEMS ='GET_ITEMS'
export const CLEAR_ITEMS ='CLEAR_ITEMS'
