import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { logout } from '../../../store/actions/auth';

const UserActions = ({logout}) => {

  return (
    <Fragment>
        {/* <span className='link' style={{'paddingRight':0}}>USER ACTIONS</span> */}
        <span onClick={()=>logout()} className='link' 
        style={{
          'verticalAlign':'super', 
          'paddingRight':0,
          'cursor':"pointer"
          }}>LOG OUT</span>
        <i className="material-icons" style={{'display': 'inline-block'}}>person</i>
    </Fragment>
  )
}

const mapStateToProps =state =>({
  id: state.auth.currentUser.authId
})

export default connect(mapStateToProps, { logout })(UserActions)