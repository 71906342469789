import React from 'react';
import loader from './loader.gif';

const Spinner = () => (
    <div style={{height: '100vh'}}>
        <img
        src={loader}
        style={{
            width: '200px', margin: 'auto', display: 'block',
            // position: 'absolute',
            transform: 'translateY(30vh)'
        }}
        alt='Loading...'
        />
    </div>
  
);

export default Spinner;