import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import Sidebar from '../components/layout/Sidebar'
import Navbar from '../components/layout/navbar/Navbar'
import PaginatedItems from '../components/layout/tables/PaginatedTable';
import TableProjects from '../components/layout/tables/TableProjects';
import M from 'materialize-css/dist/js/materialize.min.js';   
import AddProjectModal from '../components/modals/AddProjectModal';

const ProjectsPage = ({currentUserRole, projects, currentitems}) => {
    const inactive =currentUserRole==='Submitter' || currentUserRole==='Developer' ? 'notactive' : ''
   
    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    });

  return(
    <div className="box-layout" style={{'alignItems':'normal'}}>
        
        <div className="box-layout__box" style={{'width': '100%', 'padding':0}}>
            <div className='row white' style={{'marginBottom':0}}>
                <div className='col s12' style={{'padding':0}}>
                    <Sidebar />
                </div>
                <div className='col s12 offset-for-sidebar' style={{'padding':0}}>
                    <Navbar />
                    <div className='row' style={{
                        'backgroundColor': '#eaeaea',
                        'margin':'0',
                        "paddingBottom":'30px'
                         }}>

                        <h5 style={{
                            'color': '#666',
                            'paddingTop': '30px',
                            'marginTop': '3px'
                        }}>
                        My Projects</h5>
                        <div className='col s12 sec-tion'>
                            <div style={{'textAlign':'left'}}>
                                <a href='#add-project-modal'
                                className={`btn waves-effect blue darken-2 modal-trigger ${inactive}`}
                                >Add Project</a>
                            </div>
                            
                            <AddProjectModal />
                            <div className='sec-tion gradient-bground'>
                            {projects && <PaginatedItems tableItems={projects} tableTerm='projects'>
                                <TableProjects currentitems={currentitems} />
                            </PaginatedItems >}
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>               
        </div>
    </div>
)
}

ProjectsPage.propTypes ={
    projects: PropTypes.array.isRequired,
    currentUserRole: PropTypes.string.isRequired
}

const mapStateToProps =(state) =>({
    projects: state.projects.projects,
    currentUserRole: state.auth.currentUser.role
})

// export default ProjectsPage
export default connect(mapStateToProps)(ProjectsPage)