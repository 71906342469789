import React , { useEffect} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setCurrentTicket } from '../../../store/actions/ticketActions'
import { setCurrentProject } from '../../../store/actions/projectActions'
import Highlighted from './Highlighted'
import M from 'materialize-css/dist/js/materialize.min.js';   

const ticketsTH =['Title','Project Name','Developer assigned','Priority','Status','Type','Created','']

const TableTickets = (props) => {
    // const{currentitems, getTickets, ticket: { tickets, loading }, setCurrentTicket, setCurrentProject} =props
    const{currentitems, search, projects, setCurrentTicket, setCurrentProject} =props

    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    });

    const onClick =(ticket)=>{
        const project =projects.find(project=>{
            return project._id ===ticket.project.id
        })
        setCurrentTicket(ticket)
        setCurrentProject(project)
    }

    return (
    <table className='grey-text font-size-sm'>
        <thead>
        <tr>
            {
           ticketsTH !== null &&
           ticketsTH.map((el,index) =>(
            <th key={index}>{el}</th>
            ))}
        </tr>
        </thead>
        <tbody>
            {
            currentitems &&
            currentitems.map((ticket) =>(
                <tr key={ticket._id}> 
                    <td><Highlighted text={ticket.title} highlight={search}/></td>
                    <td>{ticket.project.name}</td>
                    <td><Highlighted text={ticket.developer[ticket.developer.length-1].name} highlight={search}/></td>
                    <td><Highlighted text={ticket.priority[ticket.priority.length-1]} highlight={search}/></td>
                    <td><Highlighted text={ticket.status[ticket.status.length-1]} highlight={search}/></td>
                    <td>{ticket.type}</td>
                    <td>{ticket.date[ticket.date.length-1]}</td>
                    <td>
                        <Link to={`/ticketDetails/${ticket._id}`} 
                            className='link link-underlined'
                            onClick={()=>onClick(ticket)}
                        >Details</Link>
                    </td>
                </tr>       
            ))}
        </tbody>
    </table>
  )
}

TableTickets.propTypes ={
    setCurrentTicket: PropTypes.func.isRequired,
    setCurrentProject: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    projects: state.projects.projects,
    search: state.searchItems.search
  });

// export default connect(mapStateToProps, { getTickets, setCurrentTicket, setCurrentProject })(TableTickets)
export default connect(mapStateToProps, { setCurrentTicket, setCurrentProject })(TableTickets)