import React, { useEffect} from 'react'
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getUsers } from '../store/actions/userActions';
import { getTickets } from '../store/actions/ticketActions';
import { getWarnings } from '../store/actions/warningActions';
import { getProjects } from '../store/actions/projectActions';

const PrivateRoute =({
    isAuthenticated, getProjects,getTickets,getUsers,getWarnings,
    children
})=> {
    useEffect(()=>{
        if(isAuthenticated){
            getProjects()
            getTickets()
            getUsers()
            getWarnings()
        }
    },[isAuthenticated])

    return(
    isAuthenticated ?  children: <Navigate to="/" replace={true} />
    )
}


const mapStateToProps =(state) =>({
    isAuthenticated: !!state.auth.isAuthenticated
  });

const actions={
    getProjects,
    getTickets,
    getUsers,
    getWarnings
}
  
  export default connect(mapStateToProps, actions)(PrivateRoute);