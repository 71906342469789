const Highlighted = ({text = '', highlight = ''}) => {
    if (!highlight.trim()) {
      return <span>{text}</span>
    }
    const regex = new RegExp(`(${highlight})`, 'gi')
    const parts = text.split(regex)
    return (
      <span>
         {parts.filter(part => part).map((part, i) => (
             regex.test(part) ? 
             <mark style={{'backgroundColor':"inherit", 'color':'#1976d2', 'fontWeight':'bold', 'fontSize':'larger'}} key={i}>{part}</mark> : 
             <span key={i}>{part}</span>
         ))}
     </span>
    )
 }

 export default Highlighted