import { CLEAR_ITEMS, GET_ITEMS, SET_ITEMS, UPDATE_ITEMS, UPDATE_ITEMS_TOP } from "./types"

export const setItems =(items) =>dispatch =>{
    dispatch({type: SET_ITEMS, payload: items})
}

export const updateItems =(items, search) =>dispatch =>{
    dispatch({
        type: UPDATE_ITEMS,
        payload: {items,search}
    })
}

export const updateItemsTop =(itemsTop,searchTop) =>dispatch =>{
    dispatch({
        type: UPDATE_ITEMS_TOP,
        payload: {itemsTop,searchTop}
    })
}

export const getItems =() =>dispatch =>{
    dispatch({type:GET_ITEMS})
}

export const clearItems =() =>dispatch =>{
    dispatch({type: CLEAR_ITEMS})
}