import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { addProject } from '../../store/actions/projectActions';
import M from 'materialize-css/dist/js/materialize.min.js';
import { addProjectFunc } from '../../functions/addProject';

// const AddProjectModal = ({ addProject, user: { users, loading } }) => {
  const AddProjectModal = ({ addProject}) => {

  const [projectName, setProjectName] = useState('');
  const[description, setDescription ] =useState('')
 
  const date =`${moment().format('DD-MM-YYYY, HH:mm')}`
  const args ={
    name:projectName,
    description,
    // userID: null,
    addProject,
    date
    // users
  }
 
  const onSubmit = () => {
    if (projectName === '' || description ==='') {
      M.toast({ html: 'Please enter a Project Name & Description', classes: 'toastStyle' });
    } else {
      addProjectFunc(args)
      
      M.toast({ html: `Project added`,classes: 'toastStyle' });

      // Clear Fields
      setProjectName('');
      setDescription('')
    }
  };

  return (
    <div id='add-project-modal' className='modal' style={modalStyle}>
      <div className='modal-content'>
        <h4>Enter New Project</h4>
        <div className='row'>
          <div className='input-field'>
            <input
              type='text'
              name='projectName'
              value={projectName}
              onChange={e => setProjectName(e.target.value)}
            />
            <label htmlFor='projectName' className='active'>
              Project Name:
            </label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field'>
            <input
              type='text'
              name='description'
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            <label htmlFor='description' className='active'>
              Description:
            </label>
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <a
          href='#!'
          onClick={onSubmit}
          className='modal-close waves-effect blue waves-light btn'
        >
          Enter
        </a>
      </div>
    </div>
  );
};

AddProjectModal.propTypes = {
  addProject: PropTypes.func.isRequired
};

const mapStateToProps =state =>({
  user: state.users
})

const modalStyle = {
  width: '75%',
  height: '75%'
};

export default connect( mapStateToProps,{ addProject })(AddProjectModal);