import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

// document.styleSheets[0].insertRule("@media only screen and (max-width : 1024px) { aside { transform: translateY(-105%); } }","");

const Sidebar = ({currentUser}) => {
    const location =useLocation()

  return (
    <Fragment>
    <aside  id="slide-out" className='sidenav sidenav-fixed white grey-text sidebar' 
    style={{'fontSize':'1.4rem'}}>
        <div className='greet'>
            <div className='row' style={{'margin': '10px 0'}}>
                <div className='col s5'>
                {
                    !currentUser.avatar ? 
                    <Fragment>
                        <img src='/img/avatar-min.png' alt='avatar'
                        style={{'transform':'scale(1.5','caretColor': 'transparent'}}/>
                    </Fragment>
                    : 
                        <img src={`${currentUser.avatar}`} alt='avatar'
                        style={{'transform':'scale(1.5','caretColor': 'transparent'}}/>
                        
                }
                </div>
                <div className='col s7'>
                    Welcome, {currentUser.firstName}
                </div>
            </div>         
        </div>
        {/* <hr style={{'margin': '0 10px', 'border':'1px solid #f5f5f5'}}/>
        <div className='row' style={{'margin': '10px 0'}}>
            <span>Project Name</span>
        </div>    */}
        <hr style={{'margin': '0 10px', 'border':'1px solid #f5f5f5'}}/>

        {/* <ul id="slide-out"  className="sidenav sidenav-fixed" style={{'width': '16.6666666667%'}}> */}
        {/* <ul id="nav-mobile" className="right hide-on-med-and-down"> */}
        <ul style={{'margin':0, 'paddingTop':'10px'}}>
            <li 
                className={location.pathname.includes('dashboard') ? 'active' : "inactive"}
            >
                <Link to="/dashboard">
                    <i className='material-icons aside-link'>dashboard</i>
                    <span>Dashboard Home</span>
                </Link>
            </li>
            <li
                className={location.pathname.includes('roles') ? 'active' : "inactive"}
            >
                <Link to="/roles" style={{'lineHeight':'24px'}}>
                    <i className='material-icons aside-link'>group_add</i>
                    <span>Manage Role Assignment</span>
                </Link>
            </li>
            <li
                className={location.pathname.includes('projects') ? 'active' : "inactive"}
            >
                <Link to="/projects">
                    <i className='material-icons aside-link'>reorder</i>
                    <span>My Projects</span>
                </Link>
            </li>      
            <li
                className={location.pathname.includes('tickets') ? 'active' : "inactive"}
            >
            <Link to="/tickets">
                    <i className='material-icons aside-link'>list</i>
                    <span>My Tickets</span>
                </Link>
            </li> 
            <li
                className={location.pathname.includes('profile') ? 'active' : "inactive"}
            >
            <Link to="/profile">
                    <i className='material-icons aside-link'>person</i>
                    <span>User Profile</span>
                </Link>
            </li> 
        </ul>
   
    {/* <a href="#" data-target="slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></a> */}
          
    </aside>
    </Fragment>
  )
}
const mapStateToProps =state=>({
    currentUser: state.auth.currentUser
})
 

export default connect(mapStateToProps)(Sidebar)