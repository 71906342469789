import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { getTickets } from '../../actions/ticketActions';
import {
  Chart as ChartJS,
  CategoryScale,
  Legend,
  LinearScale,
  BarElement,
  Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  Legend,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
);

const VBarChart =({chartType, ticket: { tickets, loading }}) =>{
// const VBarChart =({chartType}) =>{
    const[chartData, setChartData] =useState([])
    // useEffect(() => {
    //     getTickets();
    //     // eslint-disable-next-line
    //   }, []);

    useEffect(() =>{
       //Get arrays of sorted tikets:
      if(tickets){
      //By priority
      if(chartType==='priority'){
        let priorityNone =[]
       let priorityLow =[]
       let priorityMedium =[]
       let priorityHigh =[]
      
      tickets.forEach((ticket) =>{
       if(ticket.priority[ticket.priority.length-1]==='High'){priorityHigh.push(ticket)}
       if(ticket.priority[ticket.priority.length-1]==='Medium'){priorityMedium.push(ticket)}
       if(ticket.priority[ticket.priority.length-1]==='Low'){priorityLow.push(ticket)}
       if(ticket.priority[ticket.priority.length-1]==='None'){priorityNone.push(ticket)}

       setChartData([priorityNone.length, priorityLow.length, priorityMedium.length, priorityHigh.length])
        })
      }
      //By status:
      if(chartType==='status'){
        let statusNew =[]
       let statusOpen =[]
       let statusInProgress =[]
       let statusAdditionalInfoReq =[]
      
      tickets.forEach((ticket) =>{
       if(ticket.status[ticket.status.length-1].toLowerCase()==='additional info required'){statusAdditionalInfoReq.push(ticket)}
       if(ticket.status[ticket.status.length-1].toLowerCase()==='in progress'){statusInProgress.push(ticket)}
       if(ticket.status[ticket.status.length-1].toLowerCase()==='open'){statusOpen.push(ticket)}
       if(ticket.status[ticket.status.length-1].toLowerCase()==='new'){statusNew.push(ticket)}

       setChartData([statusNew.length, statusOpen.length, statusInProgress.length, statusAdditionalInfoReq.length])
        })
      }
       
       }
    },[tickets,chartType])

    const text =chartType==='priority' ? 'Tickets by Priority': 'Tickets by Status'
    const labels =chartType==='priority' ? ['None', 'Low', 'Medium', 'High']: ['New', 'Open', 'In Progress', 'Additional Info Required']
    const backgroundColor =chartType==='priority' ? ['blue', 'grey', 'yellow','red']: ['teal', 'blue', 'grey', 'violet']

    const options = {
      responsive: true,
      aspectRatio: 1,
      plugins: {
        legend: {
          display: false,
          },
        title: {
          display: true,
          text
        },
      },
    };

    const data = {
        labels,
        datasets: [
          {
            label: '',
            data: chartData,
            backgroundColor,
          }
        ],
      };
  return <Bar options={options} data={data} />;
}

VBarChart.propTypes = {
  ticket: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ticket: state.tickets
});
 
export default connect( mapStateToProps)(VBarChart);
// export default connect( mapStateToProps, { getTickets } )(VBarChart);