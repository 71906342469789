import React , { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setCurrentTicket } from '../../../store/actions/ticketActions'
import Highlighted from './Highlighted'
import M from 'materialize-css/dist/js/materialize.min.js';   

const ticketsTH =['Title','Submitter','Developer','Status','Created','']

const TableProjectTickets = (props) => {
    const{currentitems, search, loading, setCurrentTicket, tickets} =props

    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    });

    const onClick =(id)=>{
        const ticket =tickets.find((item)=>{
            return item._id===id
        })
        setCurrentTicket(ticket)
        // console.log('setCurrentTicket fireds')
    }

    return (
    <table className='grey-text font-size-sm'>
        <thead>
        <tr>
            {
            !loading &&
            ticketsTH !== null &&
            ticketsTH.map((el,index) =>(
            <th key={index}>{el}</th>
            ))}
        </tr>
        </thead>
        <tbody>
            {
            !loading &&
            currentitems &&
            currentitems.map((ticket) =>(
                <tr key={ticket.id}> 
                   {/* <td>{ticket.title}</td> */}
                   <td><Highlighted text={ticket.title} highlight={search} /></td>
                    <td>{ticket.submitter.name}</td>
                    <td>{ticket.developer.name}</td>
                    {/* <td>{ticket.status}</td> */}
                    <td><Highlighted text={ticket.status} highlight={search} /></td>
                    <td>{ticket.date[0]}</td>
                    <td>
                        <Link to={`/ticketDetails/${ticket.id}`} 
                            className='link link-underlined'
                            onClick={()=>onClick(ticket.id)}
                        >Details</Link>
                    </td>
                </tr>       
            ))}
        </tbody>
    </table>
  )
}

TableProjectTickets.propTypes ={
    setCurrentTicket: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    currentProject: state.projects.current,
    tickets: state.tickets.tickets,
    search: state.searchItems.search
  });

// export default Table
export default connect(mapStateToProps, { setCurrentTicket})(TableProjectTickets)