import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { setCurrentTicket } from '../../../store/actions/ticketActions'
import { setCurrentProject } from '../../../store/actions/projectActions'
import { Link } from "react-router-dom";
import Highlighted from "../tables/Highlighted";

const TopSearchList =(props) =>{
const{ projects, searchTop,tickets, setCurrentTicket, setCurrentProject} =props
    
const[display,setDisplay]=useState('hide')

    useEffect(() =>{
        searchTop ? setDisplay('show') : setDisplay('hide')
    },[searchTop])

    const onClick =(ticket)=>{
        const project =projects.find(project=>{
        return project._id ===ticket.project.id
        })
        setCurrentTicket(ticket)
        setCurrentProject(project)
    }
    document.addEventListener('click',()=>{
        setDisplay('hide')
    })

    return (
        <div className={`topsearch ${display}`}>
        <ul>
            {tickets &&
            tickets.map(ticket =>{
            return(  
            <li key={ticket._id}> 
                <p><Highlighted text={ticket.title} highlight={searchTop}/>{'\u00A0'}--{'\u00A0'}
                {ticket.project.name}{'\u00A0'}--{'\u00A0'}
                <Highlighted text={ticket.developer[ticket.developer.length-1].name} highlight={searchTop}/>{'\u00A0'}--{'\u00A0'}
                <Highlighted text={ticket.priority[ticket.priority.length-1]} highlight={searchTop}/>{'\u00A0'}--{'\u00A0'}
                <Highlighted text={ticket.status[ticket.status.length-1]} highlight={searchTop}/>{'\u00A0'}--{'\u00A0'}               
                    <Link to={`/ticketdetails/${ticket._id}`} 
                        className='link link-underlined'
                        onClick={()=>onClick(ticket)}
                    >Details</Link>
                </p>
            </li> 
            )    
            })}
        </ul>
        </div>
    )
}

    TopSearchList.propTypes ={
        setCurrentTicket: PropTypes.func.isRequired,
        setCurrentProject: PropTypes.func.isRequired
    }

    const mapStateToProps = state => ({
        projects: state.projects.projects,
        tickets: state.tickets.tickets,
        // searchItems: state.searchItems
  });

  export default connect(mapStateToProps, { setCurrentTicket, setCurrentProject })(TopSearchList)