import React, { Fragment, useState, useEffect } from 'react'
import Search from './Search'

const EntriesSearch = ({ setPerTable}) => {
const[number,setNumber] =useState(4)
// const[itemsPerTable, setItemsPerTable] =useState(number)

useEffect(()=>{
  if(number >0){
    // setItemsPerTable(number)
    setPerTable(number)
  }
}, [number])

  return (
    <Fragment>
      <div className='container-wrap'>
        <div className='container-entries'>
          <span>Show </span>
              <input id='entriesNumber' type='text' placeholder='5' 
                style={{
                  'padding':'.2rem',
                  'textAlign':'center'
                }}
                value={number}
                onChange={(e) =>setNumber(e.target.value)}
              />
          <span> entries</span>
          </div>
        <Search />
      </div>
    </Fragment>
  )
}
export default EntriesSearch