export const addProjectFunc = (args) => {
    const{
        name,
        description,
        addProject,
    }=args
     
    const newProject = {
      name,
      description,
      tickets: [],
      users: [],
      date: new Date()
    };

    addProject(newProject);
}