import { ADD_PROJECT, DELETE_PROJECT, GET_PROJECTS, UPDATE_PROJECT,
  SET_CURRENT_PROJECT, CLEAR_CURRENT_PROJECT,
  SET_LOADING, PROJECTS_ERROR
} from '../actions/types';

const initialState = {
    projects: [],
    // current: null,
    loading: false,
    error: null
}; 

const projectsReducer =(state = initialState, action) => {
    switch (action.type) {
      case GET_PROJECTS:
        return {
          ...state,
          projects: action.payload,
          loading: false
        };
      case ADD_PROJECT:
        return {
          ...state,
          projects: [...state.projects, action.payload],
          loading: false
        };
      case UPDATE_PROJECT:
        return {
          ...state,
          projects: state.projects.map((project)=>
           project._id===action.payload._id ? action.payload : project
          ),
          loading: false
        }
      case DELETE_PROJECT:
        return {
          ...state,
          projects: state.projects.filter(project => {
            return project._id !==action.payload}),
          loading: false         
        };
        case SET_CURRENT_PROJECT:
          return {
            ...state,
            current: action.payload
          };
          case CLEAR_CURRENT_PROJECT:
            return {
              ...state,
              current: null
            };
          case SET_LOADING:
            return {
              ...state,
              loading: true
            };
          case PROJECTS_ERROR:
            console.error(action.payload);
            return {
              ...state,
              error: action.payload,
              loading: false
            };
        default:
          return state
    }
} 

export default projectsReducer