import React from 'react'

const type =['Bugs/Errors','Feature requests','Other comments','Training']

const TypeSelectOptions = () => {
    return (
        type.map(el => (
          <option key={el} value={`${el}`}>
            {el}
          </option>
        ))
      )
}
export default TypeSelectOptions