import React from 'react';
import { connect } from 'react-redux';

const ProjectUserSelectOptions = ({ currentProject, projects}) => {
    
let project =null
if(projects && currentProject){
  project =projects.find(item =>item._id ===currentProject._id)
}

  return (
    project !==null &&
    project.users !== null &&
    project.users.map((el, index) => (
      <option key={index} value={`${el.id}`}>
        {el.name}
      </option>
    ))
  );
};

const mapStateToProps = state => ({
  currentProject: state.projects.current,
  projects: state.projects.projects
});

export default connect( mapStateToProps)(ProjectUserSelectOptions);
// export default ProjectUserSelectOptions