import { CLEAR_CURRENT_WARNING, GET_WARNINGS, ADD_WARNING, DELETE_WARNING } from '../actions/types'

const warningsReducer =(state=[], action) =>{
    switch(action.type){
        case ADD_WARNING:
            return [...state, action.payload];
        case GET_WARNINGS:
            return action.payload;
        case DELETE_WARNING:
            return state.filter((item)=>{
                return item.id !==action.payload
            });
        default: return state
    }
}

export default warningsReducer