export const delTicket =(args) =>{
    const{deleteTicket, currentTicket, updateProject, updateUser,  projects, users} =args

    deleteTicket(currentTicket._id)

// Delete ticket out of Project
    //find Project:
    const projectToUpdate =projects.find(project=>{
        return project._id===currentTicket.project.id
    })
    const updatedProject ={
        ...projectToUpdate, 
        tickets: projectToUpdate.tickets.filter(ticket=>{
            return ticket.id !==currentTicket._id
        })
    }
    updateProject(updatedProject)

// Delete ticket out of all users having this ticket
const arrOfTicketUsers =currentTicket.developer.filter((developer)=>developer.id !=='')
console.log('tiket users=',arrOfTicketUsers)///////////
arrOfTicketUsers.forEach(element => {
    console.log('Inside of each user')//////////////
    //find user;
    const userToUpdate =users.find(user=>{
        return user._id===element.id
    })

    // Delete ticket out of User
    const updatedUser ={
        ...userToUpdate,
        tickets: userToUpdate.tickets.filter(ticket=>{
            return ticket.id !==currentTicket._id
        })
    }

    updateUser(updatedUser)
});
   console.log('End of delTicket')////////
}