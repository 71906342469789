import React , { useEffect } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { updateProject, setCurrentProject} from '../../../store/actions/projectActions'
import { updateUser } from '../../../store/actions/userActions'
import { removeProjectFromUser } from '../../../functions/removeProjectFromUser'
import { addWarning } from '../../../store/actions/warningActions'
import M from 'materialize-css/dist/js/materialize.min.js';   

const projectPersonel =['User Name','Email','Role','']

const TableProjectUsers = (props) => {
    const{ user: {users}, updateUser, addWarning, currentUserRole,
    currentProject, loading, setCurrentProject, updateProject }=props
    const inactive =currentUserRole==='Submitter' || currentUserRole==='Developer' ? 'notactive' : ''

    const findTicketWithUser =(id) =>{
        return currentProject.tickets.find((ticket) => {
                return  parseInt(ticket.developer.id) === id
            })
    }
    
    const onRemove = (id,name) => {
        // M.toast(`${currentProject.users.name} ticket )
        const args ={
            id,
            project: currentProject,
            users,
            updateUser
        }
        removeProjectFromUser(args)

        // Set Warning - deleted user is used in some tickets:

        const isTicketWithUser =findTicketWithUser(id)

        if(isTicketWithUser){
            addWarning({warning:`Deleted ${name} is used in some tickets`})
        }
        //
        
        const updatedProject = {
            ...currentProject,
            users: currentProject.users.filter(user=>user.id !==id),
            date: new Date()
          };
        updateProject(updatedProject);
        setCurrentProject(updatedProject)
    M.toast({ html: 'User removed' });
    }

    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    });

    return (
    <table className='grey-text font-size-med'>
        <thead>
        <tr>
            {
            !loading &&
            projectPersonel !== null &&
            projectPersonel.map((el,index) =>(
            <th key={index}>{el}</th>
            ))}
        </tr>
        </thead>
        <tbody>
            {
            !loading &&
            currentProject.users &&
            currentProject.users.map((user, index) =>(
                <tr key={index}> 
                    <td>{user.name}</td>
                    <td>{`${user.email.slice(0,2)}*****${user.email.slice(-2)}`}</td>
                    <td>{user.role}</td>
                    <td>
                        <a href='#!' onClick={()=>onRemove(user.id,user.name)} 
                            className={`secondary-content ${inactive}`}>
                        <i className='material-icons grey-text'>delete</i>
                        </a>
                    </td>
                </tr>       
            ))}
        </tbody>
    </table>
  )
}

TableProjectUsers.propTypes ={
    setCurrentProject: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired,
    addWarning: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    loading: state.projects.loading,
    currentProject: state.projects.current,
    user: state.users,
    currentUserRole :state.auth.currentUser.role
  });

const propsActionsObj ={
    addWarning,
    setCurrentProject,
    updateProject,
    updateUser,
    removeProjectFromUser
}

// export default TableProjectUsers
export default connect(mapStateToProps, propsActionsObj)(TableProjectUsers)