import React , { useEffect } from 'react'
import { connect } from 'react-redux'
import Highlighted from './Highlighted';
import M from 'materialize-css/dist/js/materialize.min.js';   

const ticketsTH =['Submitter','Developer','Priority','Status','Comments','Date']

const TableTickets = ({currentitems,search}) => {

    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    });

    return (
    <table className='grey-text font-size-sm'>
        <thead>
        <tr>
            {
           ticketsTH !== null &&
           ticketsTH.map((el,index) =>(
            <th key={index}>{el}</th>
            ))}
        </tr>
        </thead>
        <tbody>
            {
            currentitems &&
            currentitems.map((elem, index)=>(
                <tr key={index}>
                    <td>{elem.submitter.name}</td>
                    {/* <td>{elem.developer ? elem.developer.name : ''}</td> */}
                    <td><Highlighted text={elem.developer ? elem.developer.name : ''} highlight={search} /></td>
                    {/* <td>{elem.priority ? elem.priority : ''}</td> */}
                    <td><Highlighted text={elem.priority ? elem.priority : ''} highlight={search} /></td>
                    {/* <td>{elem.status}</td> */}
                    <td><Highlighted text={elem.status} highlight={search} /></td>
                    <td>{elem.comments}</td>
                    <td>{elem.date}</td>
                 </tr>
             ))
            }
        </tbody>
    </table>
  )
}

// TableTickets.propTypes ={
//     setCurrentTicket: PropTypes.func.isRequired
// }

const mapStateToProps = state => ({
    currentTicket: state.tickets.current,
    search: state.searchItems.search
  });

// export default Table
export default connect(mapStateToProps)(TableTickets)