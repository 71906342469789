import React from 'react';
import { createRoot } from 'react-dom/client';
import AppRouter from './routers/AppRouter';
import { store } from './store/configStore'
import { Provider } from 'react-redux';
// import { history } from './routers/AppRouter';
// import { loadUser, login, logout } from './store/actions/auth';
import './styles/styles.scss';
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css/dist/js/materialize.min.js'

M.AutoInit()
console.log('index.js started')

const jsx =(
  <Provider store ={store}>
      <AppRouter />
  </Provider>  
);

const container = document.getElementById('root');
const root = createRoot(container);

const renderApp =() =>{
  root.render(
   jsx
  );
}

renderApp()
