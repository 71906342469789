import { AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, 
  LOGOUT, CURRENT_USER_LOADED, UPDATE_CURRENT_USER } from "../actions/types"

const initialState = {
  token: localStorage.getItem('T'),
  isAuthenticated: false,
  loading: true,
  currentUser: null,
  error: null
};

// console.log('initialState =', initialState)///

const authReducer =(state=initialState, action)=> {
    switch (action.type) {
      case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        // isAuthenticated: true,
        loading: false
      };     
      // case REGISTER_FAIL:
      case AUTH_ERROR:
      case LOGIN_FAIL:
      case LOGOUT:
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          loading: true,
          currentUser: null,
          error: action.payload
        };
      case UPDATE_CURRENT_USER:
      case CURRENT_USER_LOADED:
        return {...state, 
          isAuthenticated: true,
          loading: false,
          currentUser: {...action.payload}
        };
      default:
        return state
    }
  }
 
  export default authReducer