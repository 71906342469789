import { configureStore } from '@reduxjs/toolkit'
// import monitorReducersEnhancer from './enhancers/monitorReducers'
import authReducer from './reducers/authReducer'
import ticketsReducer from './reducers/ticketsReducer'
import projectsReducer from './reducers/projectsReducer'
import searchReducer from './reducers/searchReducer'
import usersReducer from './reducers/usersReducer'
import warningsReducer from './reducers/warningsReducer'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectsReducer,
    searchItems: searchReducer,
    tickets: ticketsReducer,
    users: usersReducer,
    warnings: warningsReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  // middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware().concat(loggerMiddleware),
  //   preloadedState,
  //   enhancers: [monitorReducersEnhancer],
  middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: {
          ignoredActions: ['SET_CURRENT_PROJECT'],
          // ignoredActionPaths: ['property'],
          ignoredPaths: ['projects.current.date']
      }
    })
})