// Remove project from users on Project Delete
export const removeProjectFromUsers= (args) => {
    const{ project,users,updateUser } =args
   
   project.users.forEach(projectUser => {
    users.forEach(item=>{
if(item._id===projectUser.id){ //user found in users
    const newProjects =item.projects.filter(itemProject=>itemProject.id !==project._id)
    updateUser({...item, projects: newProjects})
}
    })      
});
}

// Remove project from singular user on Remove ProjectUser
export const removeProjectFromUser =(args)=>{
    const{ id, project, users, updateUser } =args

    const index =users.map(user =>user._id).indexOf(id)
    const newProjects =users[index].projects.filter(el=>{
            return el.id !==project._id
        })
    updateUser({...users[index], projects: newProjects})
}