import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Sidebar from '../components/layout/Sidebar'
import Navbar from '../components/layout/navbar/Navbar'
import PaginatedItems from '../components/layout/tables/PaginatedTable';
import TableTickets from '../components/layout/tables/TableTickets';
import M from 'materialize-css/dist/js/materialize.min.js';   
import AddTicketModal from '../components/modals/AddTicketModal';

const TicketsPage = ({ currentitems, tickets}) => {
    const[newestTickets, setNewestTickets] =useState(tickets)
    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    });

    useEffect(()=>{
        // console.log('setNewestTickets fired')
        setNewestTickets(tickets)
    }, [tickets])

  return(
    <div className="box-layout" style={{'alignItems':'normal'}}>
        
        <div className="box-layout__box" style={{'width': '100%', 'padding':0}}>
            <div className='row white' style={{'marginBottom':0}}>
                <div className='col s12' style={{'padding':0}}>
                    <Sidebar />
                </div>
                <div className='col s12 offset-for-sidebar' style={{'padding':0}}>
                    <Navbar />
                    <div className='row' style={{
                        'backgroundColor': '#eaeaea',
                        'margin':'0',
                        "paddingBottom":'30px'
                         }}>

                        <h5 style={{
                            'color': '#666',
                            'paddingTop': '30px',
                            'marginTop': '3px'
                        }}>
                        My Tickets</h5>
                        <div className='col s12 sec-tion'>
                            <div style={{'textAlign':'left'}}>
                                <a href='#add-ticket-modal'
                                className='btn waves-effect blue darken-2 modal-trigger'
                                >Add Ticket</a>
                            </div>
                            
                            <AddTicketModal />
                            <div className='sec-tion gradient-bground'>
                            {newestTickets && <PaginatedItems tableItems={newestTickets} tableTerm='tickets'>
                                <TableTickets currentitems={currentitems} />
                            </PaginatedItems >}
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>               
        </div>
    </div>
)
}
TicketsPage.propTypes ={
    tickets: PropTypes.array.isRequired
}

const mapStateToProps =(state) =>({
    tickets: state.tickets.tickets
})

// export default TicketsPage
export default connect(mapStateToProps)(TicketsPage)