import React, {useEffect} from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import Navbar from '../components/layout/navbar/Navbar';
import Sidebar from '../components/layout/Sidebar';
import VBarChart from '../components/charts/VerticalBarChart';
import DoughnutChart from '../components/charts/DoughnutChart'

import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import Spinner from '../components/layout/Spinner';


const DashboardPage =({loading}) =>{
    // console.log('DashboardPage started ')/////////////

useEffect(() => {
    // Init Materialize JS
    M.AutoInit();
});

if(loading){return <Spinner />}

    return(
        <div className="box-layout" style={{'alignItems':'normal'}}>
            
            <div className="box-layout__box" style={{'width': '100%', 'padding':0}}>
                <div className='row white' style={{'marginBottom':0}}>
                    <div className='col s12' style={{'padding':0}}>
                        <Sidebar />
                    </div>
                    <div className='col s12 offset-for-sidebar' style={{'padding':0}}>
                        <Navbar />
                        <div className='container'>
                            <div className='row'>
                                <div className='col s12 m6'>
                                    <VBarChart chartType='priority'/>
                                </div>
                                <div className='col s12 m6'>
                                    <DoughnutChart chartType='type'/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col s12 m6'>
                                    <VBarChart chartType='status'/>
                                </div>
                                <div className='col s12 m6'>
                                <DoughnutChart chartType='user'/>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>               
            </div>
        </div>
    )
}

DashboardPage.propTypes ={
    loading: PropTypes.bool.isRequired
}

const mapStateToProps=(state)=>({
    loading: state.tickets.loading
})

// export default DashboardPage 
export default connect(mapStateToProps)(DashboardPage);