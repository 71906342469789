import React, {  Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import Sidebar from '../components/layout/Sidebar'
import Navbar from '../components/layout/navbar/Navbar'
import { updateUser } from '../store/actions/userActions';
import { updateCurrentUser } from '../store/actions/auth';
import M from 'materialize-css/dist/js/materialize.min.js';   

const UserProfilePage = ({ currentUser, updateCurrentUser, updateUser}) => {
    const alogo =currentUser.avatar
    const [file, setFile] = useState(null)
    const [url, setUrl] =useState(null)
    const [avatar, setAvatar]=useState(alogo)
    const[demo, setDemo] =useState('')
 
    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    });
    useEffect(()=>{
        // console.log('useEffect on change currentUser fired')
        setAvatar(currentUser.avatar)
        currentUser.firstName==='Demo' && setDemo('notactive')
    },[currentUser])
 
    const onChange =(e) =>{
      setFile(e.target.files[0])
    }

    function getBase64(file) {
        var reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.readAsDataURL(file);
            reader.onerror = () => {
                reader.abort();
                reject(new DOMException("Problem parsing input file."));
            };
            reader.onload = () => {
            // check out avatar size:
                const image = new Image();
                image.src =reader.result
                image.onload = function() {
                    if(image.width >32 || image.height > 32){
                        M.toast({ html: 'Avatar size 32x32px', classes: 'toastStyle' });
                    }else{
                        resolve(reader.result);
                    }
                };
            };
            // reader.readAsDataURL(file);
        });
     }
    
    const onSubmit =(e) =>{
      e.preventDefault()
      if(!file){
        return
        }
      getBase64(file).then((res)=>{
        // console.log(res)
        const{_id,firstName,lastName,email,avatar,role,joinDate,projects,tickets} =currentUser
        const user ={_id,firstName,lastName,email,avatar,role,joinDate,projects,tickets}
        updateUser({...user, avatar: res})
        updateCurrentUser({
            ...currentUser, 
            avatar: res
        })

        setUrl(res)
      })
    }

  return(
    <div className="box-layout" style={{'alignItems':'normal'}}>
        
        <div className="box-layout__box" style={{'width': '100%', 'padding':0}}>
            <div className='row white' style={{'marginBottom':0}}>
                <div className='col s12' style={{'padding':0}}>
                    <Sidebar />
                </div>
                <div className='col s12 offset-for-sidebar' style={{'padding':0}}>
                    <Navbar />
                    <div className='row' style={{
                        'backgroundColor': '#eaeaea',
                        'margin':'0',
                        "paddingBottom":'30px'
                         }}>

                        <h5 style={{
                            'color': '#666',
                            'paddingTop': '30px',
                            'marginTop': '3px'
                        }}>
                        {currentUser.firstName}'s' Profile</h5>
                        <div className='col s12 sec-tion'>                           
                            {/* <EditUserModal /> */}
                            <div className='sec-tion gradient-bground'>
                            <h5 style={{'paddingTop':'10px'}}>User Profile Details</h5>
                                <table className='grey-text font-size-med userprofile'>                                   
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5>Name: {'\u00A0'} </h5>
                                            <span> {currentUser.firstName} {currentUser.lastName}</span>
                                        </td>   
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Email: {'\u00A0'} </h5>
                                            <span> {currentUser.email}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Role assigned: {'\u00A0'} </h5>
                                            <span>{currentUser.role}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Avatar: {'\u00A0'} </h5>
                                            <div id='avatar' style={{'display':'inline-block'}}>
                                            {
                                                !avatar ? 
                                                <Fragment>
                                                    <img src='/img/avatar-min.png' alt='avatar'/>
                                                </Fragment>
                                                : 
                                                    <img src={`${avatar}`} alt='avatar'/>                                                  
                                            }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Join date: {'\u00A0'} </h5>
                                            <span>{currentUser.joinDate}</span>
                                        </td>
                                    </tr>
                                 </tbody>
                                </table>
                                <div className='row'>
                                {/* <form method="post" enctype="multipart/form-data"> */}
                                    <form onSubmit={onSubmit}>
                                    <div className='col s7' style={{'padding':'0 20px'}}>
                                        <div className="file-field input-field">
                                        <div className={`btn ${demo}`}>
                                            <i className="large material-icons">file_upload</i>
                                            <input type="file" accept=".png, .jpg, .jpeg" onChange={onChange}/>
                                        </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" type="text" 
                                                placeholder='Upload avatar 32x32px'
                                                style={{'fontSize':'12px'}}
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    <div className='col s5' style={{'marginTop':"1rem"}}>
                                    <button className={`btn waves-effect blue darken-2 ${demo}`} type="submit" name="action"
                                            style={{'width':'120px'}}
                                        >submit</button>
                                    </div>
                                    </form>                                        
                                    
                                </div>                               
                            </div> 
                        </div>
                    </div>           
                </div>
            </div>               
        </div>
    </div>
    )
}

UserProfilePage.propTypes ={
    updateUser: PropTypes.func.isRequired,
    updateCurrentUser: PropTypes.func.isRequired
}

const mapStateToProps =state=>({
    currentUser: state.auth.currentUser
})

// export default UserProfilePage
export default connect(mapStateToProps, {updateUser,updateCurrentUser})(UserProfilePage)