import axios from "axios";
import { CLEAR_CURRENT_WARNING, GET_WARNINGS, ADD_WARNING, DELETE_WARNING } from "./types";

const routerBasePath = process.env.NODE_ENV === 'development' ? '/' : '/.netlify/functions'

// get warnings:
export const getWarnings =() =>async dispatch =>{
    try{
       const res =await axios.get(`${routerBasePath}/warnings`)
       const data = await res.data;
        dispatch({type: GET_WARNINGS, payload: data}) 
    }catch(err){
        console.log('Error fetching warnings')
    }  
}

// add warning:
export const addWarning =(warning) =>async dispatch =>{
    try{
        const res =await axios.post(`${routerBasePath}/warnings`, warning)
        // {
        //     method: 'POST',
        //     body: JSON.stringify(warning),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // })
        const data =await res.json()
        dispatch({type: ADD_WARNING, payload: data})
    }catch(err){
        console.log('Error fetching warnings')
    }  
}

// Clear Current Project
export const clearCurrentWarning =() =>{
    return{
      type: CLEAR_CURRENT_WARNING
    }
  }

// delete warning:
export const deleteWarning =(id) =>async dispatch =>{
    try{
        await axios.delete(`${routerBasePath}/warnings/${id}`)
        dispatch({type: DELETE_WARNING, payload: id})
    }catch(err){    
        console.log('Error fetching warnings')
    }  
}