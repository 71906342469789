import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { loadUser, startLogin_VK, startLogin_Ya, redirectLogin_Ya } from '../store/actions/auth'
import setAuthToken from '../utils/setAuthToken';
import { Link } from 'react-router-dom'
import Spinner from '../components/layout/Spinner';
import M from 'materialize-css/dist/js/materialize.min.js';

const LoginPage = ({ auth, loadUser, startLogin_VK, startLogin_Ya, redirectLogin_Ya}) => {
  const{ isAthenticated, token, error }=auth
  // console.log('isAthenticated =', isAthenticated)//

  let [searchParams] = useSearchParams();
  const[code, setCode] =useState(null)

  // console.log('search Params =', code) ///////////
  useEffect(()=>{
    setCode(searchParams.get('code'))
  },[searchParams])

// Error on Login:
useEffect(() =>{
  error &&
  M.toast({ html: `${auth.error}`,classes: 'toastStyle' });
},[error])

  // redirect on code params received:
  useEffect(()=>{
    if(code){
      redirectLogin_Ya(code)
    }
  },[code,redirectLogin_Ya])

const navigate =useNavigate()

useEffect(()=>{
  if(token){
    // console.log('Login page access to token changed, token=', auth.token)
  setAuthToken(token) //set headers
  loadUser().then(()=>{
    navigate('/dashboard')
  })
  }
},[token,loadUser,navigate])

if(code && !error){return <Spinner />}

  return (
    <div > 
      <div className="box-layout">
      <div id="vk_auth"></div>          
         <div className="box-layout__box">
           <h4>
               <i className='material-icons box-layout__i'>bug_report</i>          
             Bug_Tracker Login</h4>
          <ul>
            <li>
              <button onClick={startLogin_VK}>Login VK</button>
            </li>
            <li>
              <button onClick={startLogin_Ya}>Login Yandex</button>
            </li>   
          </ul> 
            <p>Log in as a 
              <Link to="/logindemo"> Demo User</Link>
            </p>
         </div>
      </div>    
    </div>
  )
}

const mapStateToProps =state =>({
  auth: state.auth
})

const mapDispatchToProps =(dispatch) =>({
  startLogin_VK: () => dispatch(startLogin_VK()), ///////
  startLogin_Ya: () => dispatch(startLogin_Ya()),
  // login: (user) => dispatch(login(user)),
  loadUser: ()=>dispatch(loadUser()),
  redirectLogin_Ya: (code)=>dispatch(redirectLogin_Ya(code))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
