import React from 'react'
import { connect } from 'react-redux'

const TableTicketInfo = (props) => {
    const{currentTicket} =props
 
    return (
        <table className='grey-text font-size-med centre'>                                   
        <tbody>
            <tr>
                <td>
                    <h5>Title:</h5>
                    <span>{currentTicket.title}</span>
                </td>
                <td>
                    <h5>Message:</h5>
                    <span>{currentTicket.message}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <h5>Assigned developer:</h5>
                    <span>{currentTicket.developer[currentTicket.developer.length-1].name}</span>
                </td>
                <td>
                    <h5>Submitter:</h5>
                    <span>{currentTicket.submitter[0].name}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <h5>Project:</h5>
                    <span>{currentTicket.project.name}</span>
                </td>
                <td>
                    <h5>Priority:</h5>
                    <span>{currentTicket.priority[currentTicket.priority.length-1]}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <h5>Status:</h5>
                    <span>{currentTicket.status[currentTicket.status.length-1]}</span>
                </td>
                <td>
                    <h5>Type:</h5>
                    <span>{currentTicket.type}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <h5>Created:</h5>
                    <span>{currentTicket.date[0]}</span>
                </td>
                <td>
                    <h5>Updated:</h5>
                    <span>{currentTicket.date[currentTicket.date.length-1]}</span>
                </td>
            </tr>
        </tbody>
        </table>
    )
    }

const mapStateToProps = state => ({
    currentTicket: state.tickets.current
  });

export default connect(mapStateToProps)(TableTicketInfo)