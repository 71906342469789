import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProjectSelectOptions from '../selectOptions/ProjectSelectOptions'
import StatusSelectOptions from '../selectOptions/StatusSelectOptions';
import TypeSelectOptions from '../selectOptions/TypeSelectOptions';
import { addTicket } from '../../store/actions/ticketActions';
import { updateProject } from '../../store/actions/projectActions';
import { addTicketFunc } from '../../functions/addTicket';
import M from 'materialize-css/dist/js/materialize.min.js';

const AddTicketModal = ({ addTicket, updateProject, projects, currentUser }) => {
  const [title, setTitle] =useState('')
  const [message, setMessage] = useState('');
  const [type, setType] =useState('')
  const [projectID, setProjectID] =useState('')
  const [status, setStatus] =useState('New')


  const submitter ={
    "name": currentUser.firstName+' '+currentUser.lastName, 
    id: currentUser._id
  }

  const onSubmit = () => {
    if (message === '' || title === '' || projectID ==='' || type==='') {
      M.toast({ html: 'All fields are requireds', classes: 'toastStyle' });
    } else {

      const args ={
        addTicket, projects, updateProject,
        title, message, projectID, status, submitter, type
     }

      addTicketFunc(args)

      M.toast({ html: `Ticket added by User`, classes: 'toastStyle' });

      // Clear Fields
      setTitle('')
      // setComments('')
      setMessage('');
      // setPriority('')
      setProjectID('')
      setStatus('')
      setType('')
      // setUserID('');
    }
  };

  return (
    <div id='add-ticket-modal' className='modal' style={modalStyle}>
      <div className='modal-content'>
        <h4>Add Ticket</h4>

        <div className='row'>
          <div className='input-field'>
            <input
              type='text'
              name='title'
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <label htmlFor='title' className='active'>
              Ticket Title
            </label>
          </div>
        </div>
        
        <div className='row'>
          <div className='input-field'>
            <input
              type='text'
              name='message'
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
            <label htmlFor='message' className='active'>
              Ticket Message
            </label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field'>
            <select
              name='status'
              value={status}
              className='browser-default'
              onChange={e => setStatus(e.target.value)}
            >
              <option value='' disabled>
                Ticket Status
              </option>
              <StatusSelectOptions />
            </select>
            <label htmlFor='status' className='active'>
              Select Status
            </label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field'>
            <select
              name='type'
              value={type}
              className='browser-default'
              onChange={e => setType(e.target.value)}
            >
              <option value='' disabled>
                Select Type
              </option>
              <TypeSelectOptions />
            </select>
            <label htmlFor='type' className='active'>
              Ticket Type
            </label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field'>
            <select
              name='project'
              value={projectID}
              className='browser-default'
              onChange={e => setProjectID(e.target.value)}
            >
              <option value='' disabled>
                Select Project
              </option>
              <ProjectSelectOptions />
            </select>
            <label htmlFor='projectID' className='active'>
              Assigned to Project
            </label>
          </div>
        </div>

      </div>
      <div className='modal-footer'>
        <a
          href='#!'
          onClick={onSubmit}
          className='modal-close waves-effect blue waves-light btn'
        >
          Enter
        </a>
      </div>
    </div>
  );
};

AddTicketModal.propTypes = {
  addTicket: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired
};

const mapStateToProps =(state) =>({
  currentUser: state.auth.currentUser,
  projects: state.projects.projects
})

const modalStyle = {
  width: '75%',
  height: '75%'
};

export default connect( mapStateToProps,{ addTicket, updateProject })(AddTicketModal);
