import React from 'react'
import { Link } from 'react-router-dom'
// import { Counter } from '../useEffect';

const NotFoundPage =() =>{
    return (
      <div>
        <h2>Nothing to see here!</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
        {/* <Counter /> */}
      </div>
    );
  }

  export default NotFoundPage