import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateItemsTop } from '../../../store/actions/searchActions';
import TopSearchList from './TopSearchBarList';

const SearchBar = ({ updateItemsTop, tickets }) => {

  const[searchTop, setSearch]=useState('')
  
  useEffect(()=>{

      // console.log('searchItems =', tickets) ////////////
      if(tickets){
        const regex = new RegExp(searchTop, 'gi')
        const fiteredItemsTop =tickets.filter(item=>{
          return (
          item.title.match(regex)
          || item.developer[item.developer.length-1].name.match(regex)
          || item.status[item.status.length-1].match(regex)
          || item.priority[item.priority.length-1].match(regex)
          )
        })
        // console.log('filteredItems =', fiteredItemsTop) //
      updateItemsTop(fiteredItemsTop,searchTop)
      }      
  }, [searchTop, updateItemsTop])

  const onChange = e => {
    setSearch(e.target.value)
  };

  return (
    <Fragment>
      {/* <nav style={{ marginBottom: '30px' }} className='white'> */}
        <form className='white inline' >
          <div className='input-field' style={{'lineHeight':"initial"}}>
            <input id='search' type='search' placeholder='Search ...' 
            style={{
              'padding':'.5rem',
              "margin":0,
            }}          
            value={searchTop}
            onChange={onChange}
            />
            <label className='label-icon' htmlFor='search'>
              <i className="material-icons" style={{
                'color': 'lightgray',
                'transform': 'translate(90px, -14px)'
              }}>search</i>
            </label>
          </div>
        </form>
        <TopSearchList searchTop={searchTop}/>
    </Fragment>
  );
};
 
SearchBar.propTypes = {
  updateItemsTop: PropTypes.func.isRequired
};

const mapStateToProps =state =>({
    tickets: state.tickets.tickets
})
export default connect(mapStateToProps, { updateItemsTop })(SearchBar);
