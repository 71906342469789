import { findProjectNameById } from "./findById"
import moment from "moment"

export const addTicketFunc = (args) => {
    const{
        addTicket, projects, updateProject, 
        title, message, projectID, status, type, submitter
    }=args

    // const userName =findUserNameById(userID,users)
    const projectName =findProjectNameById(projectID,projects)
    const projectToUpdate =projects.find(project=>project._id===projectID)
    const date =`${moment().format('DD-MM-YYYY, HH:mm')}` 

    const newTicket = {
        title,
        comments: [''],
        message,
        priority: [''],
        project: {'id':projectID, 'name':projectName},
        status: [status],
        type,
        developer: [{'id':'', 'name':''}],
        submitter: [submitter],
        date: [date]
      };

      addTicket(newTicket).then((result)=>{
          const updatedProject ={
            ...projectToUpdate, 
            tickets:[
                ...projectToUpdate.tickets, 
                  {
                   id: result._id,
                   title: result.title,
                   comments: result.comments,
                   message: result.message,
                   priority: result.priority,
                   project: result.project,
                   submitter: result.submitter[0],
                   status: result.status[result.status.length-1],
                   type: result.type,
                   developer: result.developer[result.developer.length-1],
                   date: result.date
                }
            ]
        }
        console.log('updatedProject =', updatedProject)
        updateProject(updatedProject)
      })
} 