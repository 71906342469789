import React from 'react'

const priority =['High','Low','Medium','None']

const PrioritySelectOptions = () => {
    return (
        priority.map(el => (
          <option key={el} value={`${el}`}>
            {el}
          </option>
        ))
      )
}
export default PrioritySelectOptions