import axios from 'axios';
import {
  GET_USERS,
  ADD_USER,
  DELETE_USER,
  SET_LOADING,
  UPDATE_USER,
  USERS_ERROR,
  // CLEAR_CURRENT_USER,
  // SET_CURRENT_USER
} from './types';
const routerBasePath = process.env.NODE_ENV === 'development' ? '/' : '/.netlify/functions'

// Get users from server
export const getUsers = () => async dispatch => {
  try {
    setLoading();

    const res =await axios.get(`${routerBasePath}/users`);
    const data = await res.data;

// console.log('GET_USERS data =',data) /////////
    dispatch({
      type: GET_USERS,
      payload: data
    });
  } catch (err) {
    dispatch({
      type: USERS_ERROR,
      payload: err.response.statusText
    });
  }
};

// Add user to server
export const addUser = user => async dispatch => {
  try {
    setLoading();

    const res = await axios.post(`${routerBasePath}/users`, user) 
    
    const data = await res.data;
    // console.log('Adding user =',data) ////
    dispatch({
      type: ADD_USER,
      payload: data
    });
        return data
  } catch (err) {
    dispatch({
      type: USERS_ERROR,
      payload: err.response.data
    });
  }
};

// Delete User from Users
export const deleteUser = id => async dispatch => {
  try {
    setLoading();

    const msg =await axios.delete(`${routerBasePath}/users/${id}`)
console.log(msg)
    dispatch({
      type: DELETE_USER,
      payload: id
    });
  } catch (err) {
    dispatch({
      type: USERS_ERROR,
      payload: err.response.data
    });
  }
};

// Update User
export const updateUser = (user) => async dispatch =>{
  try{
    // console.log('user =', user)////
    setLoading();
    const res =await axios.put(`${routerBasePath}/users/${user._id}`, user)
    
    const data = await res.data;
// console.log('data response =',data)///

      dispatch({
        type: UPDATE_USER,
        payload: data
      });
    return data 
  } catch (err) {
    // console.log('UPDATE_USER err =', err)
    dispatch({
      type: USERS_ERROR,
      payload: err.response.data
    });
  }
}

// Set loading to true
export const setLoading = () => {
  return {
    type: SET_LOADING
  };
};
