import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import { Navigate } from "react-router-dom"
import { connect } from 'react-redux';
import Sidebar from '../components/layout/Sidebar'
import Navbar from '../components/layout/navbar/Navbar'
import TableTicketInfo from '../components/layout/tables/TableTicketInfo'
import TableTicketHistory from '../components/layout/tables/TableTicketHistory';
import PaginatedItems from '../components/layout/tables/PaginatedTable';
import M from 'materialize-css/dist/js/materialize.min.js';   
import EditTicketModal from '../components/modals/EditTicketModal';
import { deleteTicket, clearCurrentTicket } from '../store/actions/ticketActions'
import { updateProject } from '../store/actions/projectActions';
import { updateUser } from '../store/actions/userActions';
import { delTicket } from '../functions/delTicket'; 

const TicketDetailsPage = (props) => {
 const{currentTicket, currentUserRole, deleteTicket, clearCurrentTicket, currentitems,
     projects, users, updateUser, updateProject}=props
    const inactive =currentUserRole==='Submitter' ? 'notactive' : ''
    const inactive2 =currentUserRole==='Submitter' || currentUserRole==='Developer' ? 'notactive' : ''
 

    const[historyData, setHistoryData] =useState(null)

     useEffect(() =>{
        if(currentTicket){
        const {submitter,developer,priority, status,comments,date} =currentTicket

        const histData =date.map((elem, index)=>({
           submitter: submitter[index],
           developer: developer[index],
           priority: priority[index],
           status: status[index],
           comments: comments[index],
           date: date[index]
        }))
        setHistoryData(histData)
        }  
     },[currentTicket])

const onDelete =()=>{
    const args={
        deleteTicket,
        updateProject,
        updateUser,
        currentTicket,
        projects,
        users
    }
    delTicket(args)
    // deleteTicket(currentTicket._id)
    M.toast({ html: 'Ticket Deleted', classes: 'toastStyle' });
    clearCurrentTicket()
}

  return(
    <div className="box-layout" style={{'alignItems':'normal'}}>
        {!currentTicket && <Navigate to="/tickets" replace={true} />}
        {currentTicket !== null &&
        <div className="box-layout__box" style={{'width': '100%', 'padding':0}}>
            <div className='row white' style={{'marginBottom':0}}>
                <div className='col s12' style={{'padding':0}}>
                    <Sidebar />
                </div>
                <div className='col s12 offset-for-sidebar' style={{'padding':0}}>
                    <Navbar />
                    <div className='row' style={{
                        'backgroundColor': '#eaeaea',
                        'margin':'0',
                        "paddingBottom":'30px'
                         }}>

                        <h5 style={{
                            'color': '#666',
                            'paddingTop': '30px',
                            'marginTop': '3px'
                        }}>
                        Details for Ticket #{currentTicket._id}</h5>
                        <div className='col s12 sec-tion'>
                            <div className='row'>
                                <div className='col s6'>
                                    <a href='#edit-ticket-modal'
                                    className={`btn waves-effect blue darken-2 modal-trigger ${inactive}`}
                                    >Edit Ticket</a>
                                </div>
                                <div className='col s6'>
                                <span className={`btn waves-effect blue darken-2 ${inactive2}`}
                                    onClick={()=>onDelete()}
                                    >Delete Ticket</span>
                                </div>
                            </div>
                            <EditTicketModal />
                            <div className='col s12 l5'>
                                <div className='sec-tion gradient-bground'>
                                <h5 style={{'paddingTop':'10px'}}>Ticket Info</h5>
                                <TableTicketInfo />
                                </div> 
                            </div>

                            <div className='col s12 l7'>
                            <div className='sec-tion gradient-bground'>
                            <h5 style={{'paddingTop':'10px'}}>Ticket History</h5>
                            {historyData && <PaginatedItems tableItems={historyData} tableTerm='historyData'>
                                <TableTicketHistory currentitems={currentitems} />
                            </PaginatedItems >}
                            </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>               
        </div>
        }
    </div>
)
}

TicketDetailsPage.propTypes = {
    deleteTicket: PropTypes.func.isRequired,
    clearCurrentTicket: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired
  };

const mapStateToProps =state =>({
    currentTicket: state.tickets.current,
    projects: state.projects.projects,
    users: state.users.users,
    currentUserRole: state.auth.currentUser.role
})

const actionsObj={
    deleteTicket, 
    clearCurrentTicket,
    updateProject,
    updateUser
}

// export default TicketsPage
export default connect(mapStateToProps, actionsObj )(TicketDetailsPage)