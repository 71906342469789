import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import Sidebar from '../components/layout/Sidebar'
import Navbar from '../components/layout/navbar/Navbar'
import UserSelectOptions from '../components/selectOptions/UserSelectOptions';
import AddUserModal from '../components/modals/AddUserModal';
import TablePersonel from '../components/layout/tables/TablePersonel';
import PaginatedItems from '../components/layout/tables/PaginatedTable';
import M from 'materialize-css/dist/js/materialize.min.js';
import { updateUser } from '../store/actions/userActions';

const UserRolesPage = ({currentUserRole, currentUserId, updateUser, users, currentitems} ) => {
    
    const inactive =currentUserRole==='Submitter' || currentUserRole==='Developer' ? 'notactive' : ''
    const notAdmin =currentUserRole!=='Admin' ? true : false

    const [userID, setUserID] = useState('');
    const [role, setRole] =useState('')
    // console.log('users ===', users) //
    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    });
 
    const onChange=(e) => {
        setUserID(e.target.value)
    }

    const onSubmit = () => {
        if (userID === '' || role ==='') {
          M.toast({ html: 'Please enter User and Role' });
        } else {
            const findUser =users.find(user =>{

                return user._id ===userID
            })
 
            const updatedUser ={...findUser, role}
          updateUser(updatedUser);   
          M.toast({ html: `User Role assigned` });
    
          // Clear Fields
          setUserID('');
          setRole('')
        }
      };

  return(
    <div className="box-layout" style={{'alignItems':'normal'}}>
        
        <div className="box-layout__box" style={{'width': '100%', 'padding':0}}>
            <div className='row white' style={{'marginBottom':0}}>
                <div className='col s12' style={{'padding':0}}>
                    <Sidebar />
                </div>
                <div className='col s12 offset-for-sidebar' style={{'padding':0}}>
                    <Navbar />
                   
                    <div className='row' style={{
                        'backgroundColor': '#eaeaea',
                        'margin':'0',
                        "paddingBottom":'30px'
                         }}>

                    <h5 style={{
                        'color': '#666',
                        'paddingTop': '30px',
                        'marginTop': '3px'
                        }}>
                        Manage User Roles</h5>
                    <div className='col s12 m4' style={{'marginTop':'20px'}}>
                        
                        <div className='sec-tion' style={{'marginTop':'20px','textAlign':'left'}}>
                            <label style={{'fontSize': '1.6rem'}}>Select User</label>
                            <select className="browser-default" 
                                // style={{'height': '6rem'}} 
                                // multiple
                                value={userID}
                                // onChange={e => setUser(e.target.value)}
                                onChange={onChange}
                            >  
                             <option value='' disabled>-Select User/None-</option>                          
                                <UserSelectOptions rolespage={true} currentUserId={currentUserId}/>
                            </select>
                        </div>
                        <hr style={{'border':'1px solid #ddd'}}/>
                        <div className='sec-tion' style={{'marginTop':'20px','textAlign':'left'}}>
                            <label style={{'fontSize': '1.6rem'}}>Select Role to assign/change</label>
                            <select className="browser-default" 
                                value={role}
                                onChange={e => setRole(e.target.value)}
                            >  
                                <option value ="" disabled>-Select Role/None-</option>
                                <option value="Project Manager" disabled={notAdmin}>Project Manager</option>
                                <option value="Developer">Developer</option>
                                <option value="Submitter">Submitter</option>
                            </select>
                        </div>
            {/* Buttons  Modal*/}
                        <div className='sec-tion'>
                            <button className={`btn modal-close waves-effect blue darken-2 ${inactive}`} 
                            type="submit" name="action"
                            style={{'width':'108px'}}
                            onClick={onSubmit}
        //   className='modal-close waves-effect blue waves-light btn'
                            >submit</button>
                        </div>
                        <a href='#add-user-modal'
                        className={`btn waves-effect blue darken-2 modal-trigger ${inactive}`}
                        >Add User</a>
                        <AddUserModal />                     
                    </div>
                    
                    <div className='col s12 m8' style={{'paddingLeft':'20px', 'marginTop':'20px'}}>
                    <div  className='sec-tion gradient-bground'>
                    <h5 style={{'paddingTop':'10px'}}>All Personel</h5>

                    {users && <PaginatedItems tableItems={users} tableTerm='users'>
                        <TablePersonel currentitems={currentitems} />
                    </PaginatedItems >}

                    </div>
                    </div>
                    </div>
                </div>
            </div>               
        </div>
    </div>
)
}

UserRolesPage.propTypes ={
    updateUser: PropTypes.func.isRequired,
    currentUserRole: PropTypes.string.isRequired,
    currentUserId: PropTypes.string.isRequired
}

const mapStateToProps =(state) =>({
    users: state.users.users,
    currentUserRole: state.auth.currentUser.role,
    currentUserId: state.auth.currentUser._id
})

// export default UserRolesPage
export default connect(mapStateToProps, { updateUser })(UserRolesPage)