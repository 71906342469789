import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateProject, setCurrentProject } from '../../store/actions/projectActions';
import { updateUser } from '../../store/actions/userActions';
import UserSelectOptions from '../selectOptions/UserSelectOptions';
import M from 'materialize-css/dist/js/materialize.min.js';
import { editProject } from '../../functions/editProject';

const EditProjectModal = ({ updateProject, updateUser, setCurrentProject, currentProject, user: { users, loading } }) => {
  const [projectName, setProjectName] = useState(currentProject.name);
  const[description, setDescription ] =useState(currentProject.description)
  const [userID, setUserID] = useState(''); 

  const args={
    projectName,
    description,
    userID,
    currentProject,
    setCurrentProject,
    updateProject,
    updateUser,
    users
  }

  const onSubmit = () => {

    if (projectName === '' || description ==='') {
      M.toast({ html: 'Please enter a Project Name & Description' });
    } else {

      editProject(args)

      M.toast({ html: `Project edited by ...` });
      // Clear Fields
      // setProjectUser('');
    }
  };

  return (
    <div id='edit-projectUser-modal' className='modal' style={modalStyle}>
      <div className='modal-content'>
        <h4>Edit Project</h4>

        <div className='row'>
          <div className='input-field'>
            <input
              type='text'
              name='projectName'
              value={projectName}
              onChange={e => setProjectName(e.target.value)}
            />
            <label htmlFor='projectName' className='active'>
              Project Name:
            </label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field'>
            <input
              type='text'
              name='description'
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            <label htmlFor='description' className='active'>
              Description:
            </label>
          </div>
        </div>
        
        <div className='row'>
          <div className='input-field'>
            <select
              name='userID'
              value={userID}
              className='browser-default'
              onChange={e => setUserID(e.target.value)}
            >
              <option value='' disabled>
                -Select User-
              </option>
              <UserSelectOptions />
            </select>
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <a
          href='#!'
          onClick={onSubmit}
          className='modal-close waves-effect blue waves-light btn'
        >
          Submit
        </a>
      </div>
    </div>
  );
};

EditProjectModal.propTypes = {
  updateProject: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  setCurrentProject: PropTypes.func.isRequired
};
 
const mapStateToProps =state =>({
  currentProject: state.projects.current,
  user: state.users
})

const modalStyle = {
  width: '75%',
  height: '75%'
};

export default connect( mapStateToProps,{ updateProject, updateUser, setCurrentProject })(EditProjectModal);