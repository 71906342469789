import React, { useEffect } from 'react'
import { Navigate } from "react-router-dom"
import { connect } from 'react-redux';
import Sidebar from '../components/layout/Sidebar'
import Navbar from '../components/layout/navbar/Navbar'
import PaginatedItems from '../components/layout/tables/PaginatedTable';
import TableProjectUsers from '../components/layout/tables/TableProjectUsers';
import TableProjectTickets from '../components/layout/tables/TableProjectTickets';
import M from 'materialize-css/dist/js/materialize.min.js';   
import EditProjectModal from '../components/modals/EditProjectModal';

const ProjectsPage = ({projectUser, currentitems, currentProject}) => {
    
    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    },[]);

  return(
    <div className="box-layout" style={{'alignItems':'normal'}}>
        {!currentProject && <Navigate to="/projects" replace={true} />}
        {currentProject !== null &&
        <div className="box-layout__box" style={{'width': '100%', 'padding':0}}>
            <div className='row white' style={{'marginBottom':0}}>
                <div className='col s12' style={{'padding':0}}>
                    <Sidebar />
                </div>
                <div className='col s12 offset-for-sidebar' style={{'padding':0}}>
                    <Navbar />
                    <div className='row' style={{
                        'backgroundColor': '#eaeaea',
                        'margin':'0',
                        "paddingBottom":'30px'
                         }}>

                        <h5 style={{
                            'color': '#666',
                            'paddingTop': '30px',
                            'marginTop': '3px'
                        }}>
                        Project {currentProject.name} details</h5>
                        <div className='col s12 sec-tion'>
                            <div className='row grey lighten-2'>
                                <div className='col s6' style={{'textAlign':'left'}}>
                                    <span>Project name: {currentProject.name}</span>
                                </div>                          
                                <div className='col s6'>
                                    <span>Project description: {currentProject.description}</span>
                                </div>
                            </div>
                            <div style={{'textAlign':'left'}}>
                                <a href='#edit-projectUser-modal'
                                className='btn waves-effect blue darken-2 modal-trigger'
                                >Edit Project</a>
                            </div>
                            
                            <EditProjectModal />
                        <div className='divider'></div>
                        <div className='row'>
                            <div className='col s12 l5'>
                            <div className='sec-tion gradient-bground'>
                            <h5 style={{'paddingTop':'10px'}}>Assigned Personel</h5>
                            {/* {currentProject.users && <PaginatedItems tableItems={currentProject.users} tableTerm='currentProject.users'>
                                <TableProjectUsers currentitems={currentitems} />
                            </PaginatedItems >} */}
                            <TableProjectUsers />
                            </div> 
                            </div>
                            <div className='col s12 l7'>
                            <div className='sec-tion gradient-bground'>
                            <h5 style={{'paddingTop':'10px'}}>Tickets for this Project</h5>
                            {currentProject.tickets && <PaginatedItems tableItems={currentProject.tickets} tableTerm='currentProjectTickets'>
                                <TableProjectTickets currentitems={currentitems} />
                            </PaginatedItems >}
                            </div> 
                            </div>
                        </div>
                                                     
                        </div>
                    </div>
                </div>
            </div>               
        </div>
        }
    </div>
)
}

// ProjectsPage.propTypes ={
//     deleteProject: PropTypes.func.isRequired
// }

const mapStateToProps = state => ({
    currentProject: state.projects.current
  });

export default connect(mapStateToProps)(ProjectsPage)