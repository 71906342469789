import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const UserSelectOptions = ({ rolespage, currentUserId, user: { users } }) => {

  return (
    users !== null &&
    users.map((el, index) => (
      <option key={index} value={`${el._id}`}
      disabled={
        (el.firstName==='Demo') && rolespage ||
        el._id ===currentUserId ? true : false
      }
      >
        
      {/* <option key={t.id} value={t.id}> */}
        {el.firstName} {el.lastName}
      </option>
    ))
  );
};

UserSelectOptions.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.users
});

export default connect( mapStateToProps)(UserSelectOptions);