import axios from "axios";
import { ADD_PROJECT, 
  DELETE_PROJECT,
  UPDATE_PROJECT,
  GET_PROJECTS, 
  SET_CURRENT_PROJECT,
  CLEAR_CURRENT_PROJECT, 
  SET_LOADING, 
  PROJECTS_ERROR } from "./types"

  const routerBasePath = process.env.NODE_ENV === 'development' ? '/' : '/.netlify/functions'

 // Set loading to true
 export const setLoading = () => {
  return {
    type: SET_LOADING
  };
};

// Add new Project 
export const addProject = project => async dispatch => {
  try {
    setLoading();

    const res = await axios.post(`${routerBasePath}/projects`, project)
    // {
    //   method: 'POST',
    //   body: JSON.stringify(project),
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // });
    const data = await res.data;
    console.log('data = ', data)////////////

    dispatch({
      type: ADD_PROJECT,
      payload: data
    });
  } catch (err) {
    console.log(err)//////
    dispatch({
      // type: PROJECTS_ERROR,
      // payload: 
    });
  }
};

// Get Projects
export const getProjects = () => async dispatch => {
  try {
    setLoading();

    const res = await axios.get(`${routerBasePath}/projects`);
    const data = await res.data;

    dispatch({
      type: GET_PROJECTS,
      payload: data
    });
  } catch (err) {
    dispatch({
      type: PROJECTS_ERROR,
      payload: err.response
    });
  }
};

// Get Current Project:
export const setCurrentProject =project =>{
  return {
    type: SET_CURRENT_PROJECT,
    payload: project
  }
}
 // Clear Current Project
 export const clearCurrentProject =() =>{
   return{
     type: CLEAR_CURRENT_PROJECT
   }
 }

// Edit Project:
export const updateProject =project =>async (dispatch) =>{
  try{
    setLoading();
    const res =await axios.put(`${routerBasePath}/projects/${project._id}`, project)
    // {
    //   method: 'PUT',
    //   body: JSON.stringify(project),
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // })
    const data =await res.data

    dispatch({
      type: UPDATE_PROJECT,
      payload: data
    })
  }catch(err){
    dispatch({
      type: PROJECTS_ERROR,
      payload: err.response.data
    });
  }
}

export const deleteProject =(id) =>async dispatch =>{
  // console.log('Project user delete')
  try{
    setLoading();

    await axios.delete(`${routerBasePath}/projects/${id}`)
    clearCurrentProject()
 
    dispatch({
      type: DELETE_PROJECT,
      payload: id
    })
  } catch ( err ){
    dispatch({
      type: PROJECTS_ERROR,
      payload: err.response.data
    });
  }
}