import React from 'react'

const status =['Additional Info Required','In Progress','New','Open']

const StatusSelectOptions = () => {
    return (
        status.map((el, index) => 
          <option key={index} value={`${el}`}>
            {el}
          </option>
        ))
}
export default StatusSelectOptions