import axios from 'axios';
import {
    GET_TICKETS,
    SET_LOADING,
    TICKETS_ERROR,
    ADD_TICKET,
    DELETE_TICKET,
    UPDATE_TICKET,
    SEARCH_TICKETS,
    SET_CURRENT_TICKET,
    CLEAR_CURRENT_TICKET
  } from './types';

  const routerBasePath = process.env.NODE_ENV === 'development' ? '/' : '/.netlify/functions'
   
  // Get tickets from server
  export const getTickets = () => async dispatch => {
    try {
      setLoading();
  
      const res = await axios.get(`${routerBasePath}/tickets`);
      const data = await res.data;
  
      dispatch({
        type: GET_TICKETS,
        payload: data
      });
    } catch (err) {
      // dispatch({
      //   type: TICKETS_ERROR,
      //   payload: err.response.statusText
      // });
    }
  };
  
  // Add new ticket
  export const addTicket = log => async dispatch => {
    try {
      setLoading();
  
      const res = await axios.post(`${routerBasePath}/tickets`, log)
      const data = await res.data;
  
      dispatch({
        type: ADD_TICKET,
        payload: data
      });
      return data
    } catch (err) {
      dispatch({
        type: TICKETS_ERROR,
        payload: err.response.data
      });
    }
  };

  // Delete ticket from server
  export const deleteTicket = id => async dispatch => {
    try {
      setLoading();
  
      const res =await axios.delete(`${routerBasePath}/tickets/${id}`)
  
      dispatch({
        type: DELETE_TICKET,
        payload: res.data.msg
      });
      clearCurrentTicket()
      
    } catch (err) {
      dispatch({
        type: TICKETS_ERROR,
        payload: err.response.data
      });
    }
  };
  
  // Update ticket on server
  export const updateTicket = ticket => async dispatch => {
    try {
      setLoading();
  
      const res = await axios.put(`${routerBasePath}/tickets/${ticket._id}`, ticket)
  
      const data = await res.data;
  
      dispatch({
        type: UPDATE_TICKET,
        payload: data
      });
    } catch (err) {
      dispatch({
        type: TICKETS_ERROR,
        payload: err.response.data
      });
    }
  };
  
  // Search server tickets
  export const searchTickets = text => async dispatch => {
    try {
      setLoading();
  
      const res = await axios.fetch(`${routerBasePath}/tickets?q=${text}`);
      const data = await res.data;
  
      dispatch({
        type: SEARCH_TICKETS,
        payload: data
      });
    } catch (err) {
      dispatch({
        type: TICKETS_ERROR,
        payload: err.response.data
      });
    }
  };
  
  // Set current ticket
  export const setCurrentTicket = ticket => {
    return {
      type: SET_CURRENT_TICKET,
      payload: ticket
    };
  };
  
  // Clear current ticket
  export const clearCurrentTicket = () => {
    return {
      type: CLEAR_CURRENT_TICKET
    };
  };
  
  // Set loading to true
  export const setLoading = () => {
    return {
      type: SET_LOADING
    };
  };