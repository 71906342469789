import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Title, Tooltip, Legend);
 
const DoughnutChart =({chartType, ticket: { tickets, loading }, users}) =>{

  const[chartData, setChartData] =useState([])
  const[usersNames, setUsersNames] =useState([])
  // console.log('users ==', users)
 
  useEffect(() =>{
    //Get arrays of sorted tikets:
   if(tickets){
    //By type
    if(chartType==='type'){
      let typeBugError =[]
      let typeFeatureRequests =[]
      let typeTraining =[]
      let typeOtherComments =[]
    
    tickets.forEach((ticket) =>{
      // console.log('ticket =', ticket)///////////
      if(ticket.type==='Other comments'){typeOtherComments.push(ticket)}
      if(ticket.type==='Training'){typeTraining.push(ticket)}
      if(ticket.type==='Feature requests'){typeFeatureRequests.push(ticket)}
      if(ticket.type==='Bugs/Errors'){typeBugError.push(ticket)}

      setChartData([typeBugError.length, typeFeatureRequests.length, typeTraining.length, typeOtherComments.length])
      })
    }
    //By User:
    if(users){
      if(chartType==='user'){
      let usersWithTickets =[]
      let usersTicketsNumbers =[]

      users.forEach((user) =>{
        if(user.tickets.length >0){
          usersWithTickets.push( user.firstName+' '+user.lastName ) 
          usersTicketsNumbers.push( user.tickets.length )
        } 
      })
      setChartData(usersTicketsNumbers)
      setUsersNames(usersWithTickets)
      }
    }
    }
  },[tickets, users, chartType])

  const text =chartType==='type' ? 'Tickets by Type' : 'Tickets by User'
  const labels =chartType==='type' ? ['Bugs/Errors', 'Feature requests', 'Training', 'Other comments']: usersNames
  const backgroundColor_1 =[
    'rgba(255, 99, 132, 0.2)',
    'rgba(154, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)'
  ]
  const backgroundColor_2 =['green','blue','purple)']
  const backgroundColor =chartType==='type' ? backgroundColor_1 : backgroundColor_2
  const borderColor_1 = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(154, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)'
  ]
  const borderColor_2 =['green','blue','purple)']
  const borderColor =chartType==='type' ? borderColor_1 : borderColor_2

  const options = {
    responsive: true,
    plugins: {
    legend: {
        position: 'bottom',
        },
      title: {
        display: true,
        text,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: chartData,
        backgroundColor,
        borderColor,
        borderWidth: 1,
      },
    ],
  };

  if(loading){return <Spinner />}
  return <Doughnut options={options} data={data} />;
}
const mapStateToProps = state => ({
  ticket: state.tickets,
  users: state.users.users
});

// export default DoughnutChart
export default connect( mapStateToProps)(DoughnutChart);