import React, { useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PrioritySelectOptions from '../selectOptions/PrioritySelectOptions';
import StatusSelectOptions from '../selectOptions/StatusSelectOptions';
import ProjectUserSelectOptions from '../selectOptions/ProjectUserSelectOptions'
import { updateTicket as editTicket, setCurrentTicket } from '../../store/actions/ticketActions';
import { updateProject } from '../../store/actions/projectActions'
import { updateUser } from '../../store/actions/userActions';
import { editTicketFunc } from '../../functions/editTicket';
import M from 'materialize-css/dist/js/materialize.min.js';

const EditTicketModal = (props) => {
  const{ currentTicket, editTicket, setCurrentTicket, currentUser,
    updateProject, updateUser, projects, users } =props

  const [comments, setComments] = useState('');
  const [priority, setPriority] =useState(
    currentTicket ? currentTicket.priority[currentTicket.priority.length-1] : '')
  const [status, setStatus] =useState(
    currentTicket ? currentTicket.status[currentTicket.status.length-1] :'')
  const [userID, setUserID] = useState(
    currentTicket ? currentTicket.developer[currentTicket.developer.length-1].id : '');

  const submitter ={"name":`${currentUser.firstName} ${currentUser.lastName}`, id: currentUser._id}

  const onChange =(e)=>{
    setUserID(e.target.value)
  }

  const onSubmit = () => {
    if (comments === '' && userID === '' && priority === '') {
      M.toast({ html: 'Nothing to change', classes: 'toastStyle' });
    } else {
      const args={
        editTicket, projects, users, updateProject, setCurrentTicket, updateUser,
        currentTicket, comments, priority, status, userID, submitter
      }
      editTicketFunc(args)
      M.toast({ html: `Ticket edited by 'User'`, classes: 'toastStyle' });

      // Clear Fields
      setComments('');
      setPriority('')
      setStatus('')
      setUserID('');
    }
  };

  return (
    <div id='edit-ticket-modal' className='modal' style={modalStyle}>
      <div className='modal-content'>
        <h4>Edit Ticket</h4>

        <div className='row'>
          <div className='input-field'>
            <input
              type='text'
              name='comments'
              value={comments}
              onChange={e => setComments(e.target.value)}
            />
            <label htmlFor='comments' className='active'>
              Ticket Comments
            </label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field'>
            <select
              name='priority'
              value={priority}
              className='browser-default'
              onChange={e => setPriority(e.target.value)}
            >
              <option value='' disabled>
                Select priority
              </option>
              <PrioritySelectOptions />
            </select>
            <label htmlFor='priority' className='active'>
              Ticket Priority
            </label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field'>
            <select
              name='status'
              value={status}
              className='browser-default'
              onChange={e => setStatus(e.target.value)}
            >
              <option value='' disabled>
                Select status
              </option>
              <StatusSelectOptions />
            </select>
            <label htmlFor='status' className='active'>
              Ticket Status
            </label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field'>
            <select
              name='user'
              value={userID}
              className='browser-default'
              onChange={e => onChange(e)}
            >
              <option value='' disabled>
                Select developer
              </option>
              <ProjectUserSelectOptions />
            </select>
            <label htmlFor='user' className='active'>
              Assiged Developer
            </label>
          </div>
        </div>       

      </div>
      <div className='modal-footer'>
        <a
          href='#!'
          onClick={onSubmit}
          className='modal-close waves-effect blue waves-light btn'
        >
          Enter
        </a>
      </div>
    </div>
  );
};

EditTicketModal.propTypes = {
  editTicket: PropTypes.func.isRequired,
  setCurrentTicket: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

const mapStateToProps =(state) =>({
  currentTicket: state.tickets.current,
  users: state.users.users,
  projects: state.projects.projects,
  currentUser: state.auth.currentUser
})

const actionsObj ={
  editTicket,
  setCurrentTicket,
  updateProject,
  updateUser
}

const modalStyle = {
  width: '75%',
  height: '75%'
};

export default connect( mapStateToProps, actionsObj)(EditTicketModal);