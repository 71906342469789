export const findUserNameById = (id, users) => {
    if(id){
        const user =users.find(user =>{
            return user._id ===id
        })
        return user.firstName+' '+user.lastName
    }else{
        return ''
    }
}

export const findProjectNameById =(id, projects) =>{
    if(id){
        const project =projects.find(project =>{
            return project._id ===id
        })
        return project.name
    }else{
        return ''
    }
}

