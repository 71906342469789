import React , { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { updateProject} from '../../../store/actions/projectActions'
import { deleteUser} from '../../../store/actions/userActions'
import Highlighted from './Highlighted'
import M from 'materialize-css/dist/js/materialize.min.js';   

const personel =['User Name','Email','Role','']

const TablePersonel = (props) => {
    const{ currentitems, currentUserRole, search, deleteUser,
    projects, updateProject} =props
    const inactive =currentUserRole==='Submitter' || currentUserRole==='Developer' ? 'notactive' : ''
    // console.log('currentItems ==', currentitems)///

    useEffect(() => {
        // Init Materialize JS
        M.AutoInit();
    });
 
    const onRemove = (id) => {
        deleteUser(id);
    M.toast({ html: 'User Deleted', classes: 'toastStyle' });
    // Remove user fr all projects:
    if(projects){
        projects.forEach(project => {
            project.users.forEach(user =>{
                if(user.id===id){
                    const newUsers =project.users.filter(user =>user.id !==id)
                    updateProject({
                        ...project,
                        users: newUsers
                    })
                }
            })
        });
    }      
    }
 
    return (
    <table className='grey-text font-size-med'>
        <thead>
        <tr>
            {
            // !loading &&
            personel !== null &&
            personel.map((el,index) =>(
            <th key={index}>{el}</th>
            ))}
        </tr>
        </thead>
        <tbody>
            {
            // !loading &&
            // currentItems !== null &&
            currentitems &&
            currentitems.map((user) =>(
                <tr key={user._id}> 
                    <td><Highlighted text={`${user.firstName} ${user.lastName}`} highlight={search} /></td>
                    <td>{`${user.email.slice(0,2)}*****${user.email.slice(-2)}`}</td>
                    <td>{user.role}</td>
                    <td>
                        <a href='#!' onClick={()=>{onRemove(user._id)}} 
                            className={`secondary-content ${inactive}`}>
                        <i className='material-icons grey-text'>delete</i>
                        </a>
                    </td>
                </tr>       
            ))}
        </tbody>
    </table>
  )
}

TablePersonel.propTypes ={
    deleteUser: PropTypes.func.isRequired,
    // getUsers: PropTypes.func.isRequired,
    getProjects: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    projects: state.projects.projects,
    search: state.searchItems.search,
    currentUserRole: state.auth.currentUser.role
  });

const propsActionsObj ={
    // getUsers,
    deleteUser,
    updateProject
}

// export default Table
export default connect(mapStateToProps, propsActionsObj)(TablePersonel)