import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteWarning } from '../../store/actions/warningActions';

  const LogsModal =({deleteWarning, warnings}) =>{

  return (

    <div id='logs-modal' className='modal'>
      <div className='modal-content'
        // style={`${warnings.length < 1 ? {display: 'none'} : {display: 'block'}}`}
      >
        {warnings.length > 0 ? (
          <div>
          <h4>Warnings</h4>
          <ul>
            {warnings.map((warning)=>(
              <li key={warning.id}
              style={{'float':'none'}}
              >
                {warning.warning}
                <a href='#!' onClick={()=>deleteWarning(warning.id)} 
                  className='secondary-content'>
                  <i className='material-icons grey-text'>delete</i>
                </a>
              </li>
            ))}
          </ul>
          </div>
        ) : <div>No warnings</div>}
        
      </div>
    </div>
  );
};

LogsModal.propTypes = {
  deleteWarning: PropTypes.func.isRequired
};
 
const mapStateToProps =state =>({
  warnings: state.warnings
})

// const modalStyle = {
//   width: '75%',
//   height: '75%'
// };

export default connect( mapStateToProps, { deleteWarning })(LogsModal);