import { findUserNameById } from "./findById"
import moment from "moment"

const date =`${moment().format('DD-MM-YYYY, HH:mm')}`

export const editTicketFunc = (args) => {
    const{
        editTicket, projects, users, updateProject, setCurrentTicket, updateUser,
        currentTicket, comments, priority, status, userID, submitter
    }=args
    const userName =findUserNameById(userID,users) //name or ''
    const projectToUpdate =projects.find(project=>project._id===currentTicket.project.id)

      const updatedTicket = {
        ...currentTicket,
        comments :[...currentTicket.comments, comments],
        priority: [...currentTicket.priority, priority],
        status: [...currentTicket.status, status],    
        developer: [...currentTicket.developer, {'id':userID, 'name':userName}],
        submitter: [...currentTicket.submitter, submitter],
        date: [...currentTicket.date, date]
      };
      console.log('updatedTicket==', updatedTicket)////////

      editTicket(updatedTicket);

      // Update Project's Ticket

      const updatedTicketToProject ={
        id: updatedTicket._id,
        comments: updatedTicket.comments,
        message: updatedTicket.message,
        priority: updatedTicket.priority,
        project: updatedTicket.project,
        title: updatedTicket.title,
        type: updatedTicket.type,
        submitter: updatedTicket.submitter[0],
        status: updatedTicket.status[updatedTicket.status.length-1],
        developer: updatedTicket.developer[updatedTicket.developer.length-1],
        date: updatedTicket.date
      }
     // Creat new tickets array for project:

      const newTicketsArray =projectToUpdate.tickets.map(ticket=>{
          if(ticket.id !==currentTicket._id){
              return ticket
          }else{
            return updatedTicketToProject
        }
      })

    updateProject({...projectToUpdate, tickets:newTicketsArray})
      
    //Update User tickets
    if(userID){
      const userToUpdate =users.find(user=>user._id===userID)
      const isUserTicketExists =userToUpdate.tickets.find((ticket =>{
        return ticket.id===currentTicket._id
      }))
      if(!isUserTicketExists){
        
        const updatedUser ={
          ...userToUpdate,
          tickets: [
              ...userToUpdate.tickets, 
              {
                  title: currentTicket.title, 
                  id: currentTicket._id,
                  message: currentTicket.message
              }]
          }
          console.log('updatedUser ==', updatedUser)

          updateUser(updatedUser)    
      } 
    }
    setCurrentTicket(updatedTicket) 
}