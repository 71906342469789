import React, {Fragment, useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import EntriesSearch from "./EntriesSearch";
import { getItems, setItems } from "../../../store/actions/searchActions";

const PaginatedItems =(props) =>{
  const{ items, setItems, tableItems, tableTerm, projects, tickets, users } =props
  
  const [itemsPerPage,setItemsPerPage] =useState(4)
      // console.log('items ==', items)///
    // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(()=>{
    setItems({
      filteredItems: tableItems,
      tableItems, 
      tableTerm
    })
  },[users,projects,tickets, setItems, tableItems, tableTerm])

  // useEffect(()=>{
    // console.log('currentitems =', currentItems)
  // },[currentItems])


   useEffect(() => {
    // Fetch items from another resources.

    if(items && itemsPerPage >0){
      const endOffset = itemOffset + parseInt(itemsPerPage);
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);///
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / parseInt(itemsPerPage)));
    }
    
  }, [itemOffset, itemsPerPage, items]);

  //set itemsPerPage:
  const setPerTable= (number)=>{
    setItemsPerPage(number)
    setItemOffset(0)  // set start from beginning
  }

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };
    return (
        <Fragment>
          <EntriesSearch setPerTable={setPerTable} />
          {React.cloneElement(props.children, { currentitems: currentItems })}
          <ReactPaginate
            containerClassName="pagination"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        </Fragment>
      );
}

PaginatedItems.propTypes ={
  setItems: PropTypes.func.isRequired,
  getItems: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    items: state.searchItems.filteredItems,
    projects: state.projects.projects,
    tickets: state.tickets.tickets,
    users: state.users.users
  });

// export default connect(mapStateToProps, { getUsers })(PaginatedItems)
export default connect(mapStateToProps, {getItems, setItems})(PaginatedItems)