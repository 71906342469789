import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateItems } from '../../../store/actions/searchActions';

const Search =({ updateItems, table: {tableTerm, tableItems} })=>{
//   const text = useRef('');
    const[search, setSearch]=useState('')

    useEffect(()=>{

    //   const onChange = e => {
        // console.log('searchItems =', tableItems) ////////////
        if(tableItems){
             const regex = new RegExp(search, 'gi')
        const fiteredItems =tableItems.filter(item=>{
            
            switch(tableTerm){
                default: return item;
                case 'tickets':
                    return (item.title.match(regex)
                    || item.developer[item.developer.length-1].name.match(regex)
                    || item.status[item.status.length-1].match(regex)
                    || item.priority[item.priority.length-1].match(regex)
                    )
                case 'projects':
                    return item.name.match(regex);
                case 'users':
                    return item.firstName.match(regex) || item.lastName.match(regex);
                case 'currentProjectTickets':
                    return (item.title.match(regex)
                    || item.status[item.status.length-1].match(regex)
                    )
                case 'historyData':
                    return (item.developer.name.match(regex)
                    || item.status.match(regex)
                    || item.priority.match(regex)
                    )
            }

        })
        //   console.log('filteredItems =', fiteredItems) //
        updateItems(fiteredItems,search)
        }      
  //};
    }, [search, tableItems, tableTerm, updateItems])

  return (
    <div className='container-search'>
        <span>Search  </span>
        <input id='searchEntries' 
            type='search'
            // ref={text}
            value={search}
            // onChange={onChange} 
            onChange={(e)=>setSearch(e.target.value)}
            style={{
              'padding':'.2rem',
              'textAlign':'center'
              }}/>
      </div>
  );
};

Search.propTypes = {
  updateItems: PropTypes.func.isRequired
};

const mapStateToProps =state =>({
    table: state.searchItems
})

export default connect(mapStateToProps, { updateItems })(Search);