import React from 'react'
import { connect } from 'react-redux'
import SearchBar from './SearhBar'
import UserActions from './UserLogout'
import Notifications from './Notifications'

const Navbar =({currentUser}) =>{

  return (
    <nav className='white grey-text' style={{'position': 'sticky', 'top':0, 'zIndex':10}}>
    <div className="nav-wrapper" >
        <div className='row' style={{'marginLeft':0,'marginRight':0}}>
             <div className="col s12 m6 l4">

             <a href="#" data-target="slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></a>
            <span className="left" style={{'fontSize':'16px'}}>Logged in as {currentUser.role}</span>
            {/* <ul id="nav-mobile" className="right hide-on-med-and-down"> */}
        </div>
        <div className="col s12 m6 l8">    
            <ul id="nav-mobile" className="right">
                <li><SearchBar /></li>
                <li><Notifications /></li>
                <li><UserActions /></li>
            </ul>

            </div>   
        </div>
    </div>
  </nav>
  )
}
const mapStateToProps =state =>({
  currentUser: state.auth.currentUser
})
 
export default connect(mapStateToProps)(Navbar)