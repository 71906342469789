import {
    GET_USERS,
    ADD_USER,
    DELETE_USER,
    SET_LOADING,
    USERS_ERROR,
    UPDATE_USER,
    // CLEAR_CURRENT_USER,
    // SET_CURRENT_USER
  } from '../actions/types';
  
  const initialState = {
    users: [],
    // current: null,
    loading: false,
    error: null
  };
  // console.log('Users init state=', initialState)/////
  
  const usersReducer =(state = initialState, action) => {
    // console.log('Users action=', action.type)
    // console.log('users reducer action.payload =', action.payload)

    switch (action.type) {
      case GET_USERS:
        return {
          ...state,
          users: action.payload,
          loading: false
        };
      case ADD_USER:
        return {
          ...state,
          users: [...state.users, action.payload],
          loading: false,
          error: null
        };
      case UPDATE_USER:
        return {
          ...state,
          users: state.users.map((user)=>
           user._id===action.payload._id ? action.payload : user
          ),
          loading: false,
          error: null
      };
      case DELETE_USER:
        return {
          ...state,
          users: state.users.filter(user => user._id !== action.payload),
          loading: false,
          error: null
        };
      case SET_LOADING:
        return {
          ...state,
          loading: true
        };
      case USERS_ERROR:
        console.error(action.payload);
        return {
          ...state,
          error: action.payload,
          loading: false
        };
      default:
        return state;
    }
  };

  export default usersReducer