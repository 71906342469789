import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LogsModal from '../../modals/LogsModal';

const Notifications = ({warnings}) => {

  return (
      <div style={{'marginRight':'-36px'}}>
          <span className="notific">NOTIFICATIONS</span>
          <i className="material-icons" style={{'display': 'inline-block'}}>notifications</i>
            <a
                // href={`${warnings.length > 0  ? '#logs-modal': '#!'}`}
                href='#logs-modal'
                className='btn-floating btn red darken-2 modal-trigger notific-btn'             
            >
                <span style={{
                    'display':'inline-block',
                    'transform':'translateY(-10px)'}}
                >
                   {warnings.length}</span>
            </a>
            <LogsModal />
      </div>
  )
}
Notifications.propTypes ={
    ticket: PropTypes.array.isRequired
}

const mapStateToProps =state =>({
    warnings: state.warnings
})

export default connect(mapStateToProps)(Notifications)